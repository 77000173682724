import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() multiple: boolean = false;
  @Input() submitted: boolean = false;
  @Input() label: string = 'File';
  @Input() labelSmall: boolean = false;

  @Output() fileListChange = new EventEmitter<File[]>();

  @ViewChild('fileSelect') fileSelect: ElementRef;

  files: File[] = [];

  constructor() { }

  ngOnInit() {
  }

  onFileChange(event: any) {
    this.files = [];
    for(let file of event.target.files){
      this.files.push(file);
    }

    this.fileListChange.emit(this.files);
  }

  deleteFile(fileToDelete: File) {
    this.files = this.files.filter(f => f.name != fileToDelete.name);

    this.fileListChange.emit(this.files);
  }
}
