import { Pipe, PipeTransform } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { ITableColConfigurations } from "../classes/interfaces";
import { GetTableValuePipe } from "./gettablevaluepipe";
import { eValueType } from "../classes/enums";

@Pipe({ name: 'getStatusColor' })
export class GetStatusColorPipe implements PipeTransform {
  constructor(private getTableValuePipe: GetTableValuePipe) {
  }

  transform(item: any, conf: ITableColConfigurations): any {
    let value = this.getTableValuePipe.transform(item, conf);
    let color = 'light-gray';

    if (conf.iconStatus) {
      color = conf.iconStatus.defaultColor;
      if (conf.iconStatus.statuses?.length > 0) {
        for (let s of conf.iconStatus.statuses) {
          switch (conf.iconStatus.valueType) {
            case eValueType.string:
              if (String(s.value) === value && s.color) {
                return s.color;
              }
              break;
            case eValueType.number:
              if (Number(s.value) === value && s.color) {
                return s.color;
              }
              break;
            case eValueType.boolean:
              if (Boolean(s.value) === value && s.color) {
                return s.color;
              }
              break;
            default:
              break;
          }
        }
      }
    }

    return color;
  }
}
