import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventService, Events } from 'src/app/services/events.service';
import { MQTTEvents, MqttWrapperService } from 'src/app/services/mqtt-wrapper.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  isLoggedIn: boolean = false;
  userInfo: string = '';

  constructor(private mqttWrapperService: MqttWrapperService,
              private eventService: EventService,
              private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.attachEvents();
  }

  ngOnDestroy(): void {
      this.removeEvents();
  }

  private attachEvents() {
    this.subscriptions.push(this.mqttWrapperService.on(MQTTEvents.onConnectionStateChange, (value)=> {
      // this.status = <boolean>value ? 'Connesso' : 'Disconnesso';
    }));

    this.subscriptions.push(this.mqttWrapperService.on(MQTTEvents.onNewMessage, (message: IMqttMessage)=> {
      let topic = message.topic;
      let text = message.payload.toString();
      console.log('topic, text', topic, text);
    }));

    this.subscriptions.push(this.eventService.on(Events.loginStateChanged, (value: boolean) => {
      this.setUser(value);
    }));
  }

  private removeEvents() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private setUser(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;

    if (this.isLoggedIn) {
      let user = this.authService.currentUserValue.user;
      this.userInfo = user.name;
    }
    else {
      this.userInfo = '';
    }
  }
}
