import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFormConfig } from 'src/custom-component-lib/classes/interfaces';

@Component({
  selector: 'my-check-box',
  templateUrl: './my-check-box.component.html',
  styleUrls: ['./my-check-box.component.scss']
})
export class MyCheckBoxComponent  implements OnInit {
  @Input() config: IFormConfig = null;
  @Input() formId: string = '';
  @Input() showLabel: boolean = true;
  @Input() value: any;
  @Input() label: string = '';
  @Input() small: boolean = false;

  @Output() onChange = new EventEmitter<any>();

  constructor() {

  }

  ngOnInit() {

  }
}
