import {Injectable} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {filter, map} from "rxjs/operators";
import { ISystem } from "../models/data.model";

export enum Events{
  'loginStateChanged',
  'userProfileChanged',
  'translationsLoaded',
  'generalQuestionAnswer',
  'configurationChanged'
}

export class EmitEvent {
  name: Events;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private subject$ = new Subject()

  emit(event: EmitEvent) {
    this.subject$.next(event);

  }

  on(event: Events, action: any): Subscription {
    return this.subject$.pipe(
        filter((e: EmitEvent) => e.name == event),
        map((e: EmitEvent) => e.value)).subscribe(action);
  }

  onLoginStateChange(value: boolean) {
    this.emit({name: Events.loginStateChanged, value: value});
  }

  onTranslationsLoaded() {
    this.emit({name: Events.translationsLoaded, value: null});
  }

  onUserProfileChange() {
    this.emit({name: Events.userProfileChanged, value: null});
  }

  onGeneralQuestionAnswer(value: boolean) {
    this.emit({name: Events.generalQuestionAnswer, value: value});
  }

  onConfigurationChanged(value: ISystem) {
    this.emit({name: Events.configurationChanged, value: value});
  }
}
