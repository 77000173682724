//Need to install: npm install ngx-pagination --save
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {eTableItemType, eTableSortStatus, eTableStatusIconType, eTextAlign} from "../classes/enums";
import {
  ITableColConfigurations,
  ITableConfiguration, ITableFilter,
  ITableFilterColConfiguration,
  ITableSortSelected
} from "../classes/interfaces";
import {dateUtils} from "../classes/utility";

@Component({
  selector: 'custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit, OnChanges {
  @Input() paginationID: string = 'pagination1';
  @Input() title: string = '';
  @Input() configuration: ITableConfiguration;
  @Input() items: any[] = [];
  @Input() showCollectionSize: boolean = false;
  @Input() isFiltered: boolean = false;
  @Input() previousBtnLbl: string = "";
  @Input() nextBtnLbl: string = "";
  @Input() page: number = 1;
  @Input() itemsPerPage: number = 0;
  @Input() totalItems: number = 0;
  @Input() markSelected: boolean = false;
  @Input() currentItemSelected: any;
  @Input() currencySymbol: string = '€';
  @Input() currentSortStatus: ITableSortSelected;
  @Input() addButtonPresent: boolean = false;
  @Input() showItemsPerPage: boolean = false;
  @Input() showTotalItems: boolean = true;
  @Input() minItemsPerPage: number = 1;
  @Input() maxItemsPerPage: number = 40;
  @Input() smallRow: boolean = false;
  @Input() hideRowsBorder: boolean = false;
  @Input() hideHeadersBorder: boolean = false;
  @Input() multiSelectEnabled: boolean = false;
  @Input() highlightRowOnMultiselect: boolean = false;
  @Input() columnsPaginationEnabled: boolean = false;
  @Input() columnsPerPage: number = 6;
  @Input() rowClickEnabled: boolean = true;

  @Output() onPageChange = new EventEmitter<number>();
  @Output() onItemSelected = new EventEmitter<any>();
  @Output() onThumbnailSelected = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onDownload = new EventEmitter<any>();
  @Output() onCustom = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<ITableSortSelected>();
  @Output() onAdd = new EventEmitter();
  @Output() onFilterChange = new EventEmitter<ITableFilter>();
  @Output() onItemPerPageChange = new EventEmitter<number>();
  @Output() onMultiSelectChange = new EventEmitter<any[]>();
  @Output() onColumnPageChange = new EventEmitter<number>();
  @Output() onIconLinkClick = new EventEmitter<{columnConfig: ITableColConfigurations, item: any}>();

  sortingHeaderStatus: eTableSortStatus[] = [];

  eTableItemType = eTableItemType;
  eTableSortStatus = eTableSortStatus;
  eTableTextAlign = eTextAlign;
  eTableStatusIconType = eTableStatusIconType;

  selectedFilter: ITableFilterColConfiguration;
  selectedFilterKey: string = 'none';
  selectedFilterValue: string = '';

  selectedRows: any[] = [];
  allRowsSelected: boolean = false;
  selectedRowsIndeterminate: boolean = false;

  numbersToLoop: number[] = [];

  currentColumPage: number = 1;
  lastColumnPage: number = 0;

  constructor() {

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configuration'] && this.configuration) {
      if (this.configuration.cols.length > 0) {
        for (let i = 0; i < this.configuration.cols.length; i++) {
          let r = this.configuration.cols[i];
          if (r.sortEnabled) {
            this.sortingHeaderStatus[r.key] = eTableSortStatus.none;
          }
        }
      }

      if (this.columnsPaginationEnabled) {
        this.initMultiPageColumns();
      }
    }

    if (changes['currentSortStatus'] && this.currentSortStatus) {
      this.sortingHeaderStatus[this.currentSortStatus.key] = this.currentSortStatus.status;
    }

    if ((changes['minItemsPerPage'] || changes['maxItemsPerPage'] || changes['showItemsPerPage']) && this.showItemsPerPage) {
      this.setItemsNumbers();
    }

    if (changes['items'] && this.items != null) {
      this.resetMultiSelect();
    }
  }

  private initMultiPageColumns() {
    this.lastColumnPage = Math.ceil(this.configuration.cols.length / this.columnsPerPage);

    this.setMultiPageColumnsArray();
  }

  private setMultiPageColumnsArray() {
    let mRows: ITableColConfigurations[] = [];

    let start = (this.currentColumPage - 1) * this.columnsPerPage;
    let end = (this.currentColumPage) * this.columnsPerPage;

    if (end > this.configuration.cols.length) {
      end = this.configuration.cols.length;
    }

    for (let i = start; i < end; i++) {
      mRows.push(this.configuration.cols[i]);
    }
    this.configuration.multiPageCols = mRows;
  }

  private setItemsNumbers() {
    let n: number[] = [];
    for (let i = this.minItemsPerPage; i <= this.maxItemsPerPage; i++) {
      n.push(i);
    }

    this.numbersToLoop = n;
  }

  private prepareItems() {
    for (const item of this.items) {
      for (const col of this.configuration.cols) {
        switch (col.type) {
          case eTableItemType.boolean:
          case eTableItemType.currency:
          case eTableItemType.date:
          case eTableItemType.number:
          case eTableItemType.text:
            item
            break;

          default:
            break;
        }
      }
    }
  }

  itemSelected(item: any) {
    this.currentItemSelected = item;
    this.onItemSelected.emit(item);
  }

  onEditClick(item: any) {
    this.itemSelected(item);
    this.onEdit.emit(item);
  }

  onDeleteClick(item: any) {
    if (this.markSelected) {
      this.itemSelected(item);
    }
    this.onDelete.emit(item);
  }

  onDownloadClick(item: any) {
    this.itemSelected(item);
    this.onDownload.emit(item);
  }

  onCustomClick(item: any) {
    this.itemSelected(item);
    this.onCustom.emit(item);
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.onPageChange.next(this.page);
  }

  onSortPressed(key: string) {
    switch (this.sortingHeaderStatus[key]) {
      case eTableSortStatus.none:
        this.sortingHeaderStatus[key] = eTableSortStatus.asc;
        break;
      case eTableSortStatus.asc:
        this.sortingHeaderStatus[key] = eTableSortStatus.desc;
        break;
      default:
        this.sortingHeaderStatus[key] = eTableSortStatus.asc;
        break;
    }

    this.currentSortStatus = {key: key, status: this.sortingHeaderStatus[key]};

    for (let i = 0; i < this.configuration.cols.length; i++) {
      let r = this.configuration.cols[i];
      if (r.sortEnabled && r.key != key) {
        this.sortingHeaderStatus[r.key] = eTableSortStatus.none;
      }
    }

    this.onSort.emit(this.currentSortStatus);
  }

  // getValue(item: any, conf: ITableColConfigurations) {
  //   let key = conf.key.replace(/->/g, '.');
  //   let value = null;
  //   if (item) {
  //     try {
  //       value = key.split('.').reduce(function(p,prop) { return p[prop] }, item);
  //     } catch (error) {}
  //   }

  //   if (value) {
  //     switch (conf.type) {
  //       case eTableItemType.date:
  //         if (!conf.dateOptions?.showOnlyDate && !conf.dateOptions?.showOnlyTime) {
  //           value = dateUtils.getDateTime(value, '-', !conf.dateOptions?.hideSeconds, conf.dateOptions?.dateTimeUTC);
  //         }
  //         else if (conf.dateOptions?.showOnlyDate) {
  //           value = dateUtils.getDate(value, conf.dateOptions?.separator != null ? conf.dateOptions?.separator : '-',
  //                                     conf.dateOptions?.startFormat != null ? conf.dateOptions?.startFormat : 'dd/MM/yyyy');
  //         }
  //         else if (conf.dateOptions?.showOnlyTime) {
  //           value = dateUtils.getTime(value, !conf.dateOptions?.hideSeconds);
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   }

  //   return value;
  // }

  multiSelectChange(item: any, value: boolean) {
    item.selected = value;

    this.selectedRows = this.items.filter(e => e.selected);
    this.allRowsSelected = this.selectedRows.length == this.items.length;
    this.selectedRowsIndeterminate = this.selectedRows.length > 0 && this.selectedRows.length < this.items.length;
    this.onMultiSelectChange.emit(this.selectedRows);
  }

  resetMultiSelect() {
    this.selectedRows = [];
    this.allRowsSelected = false;
    this.selectedRowsIndeterminate = false;
    this.onMultiSelectChange.emit(this.selectedRows);
  }

  selectAllClick() {
    this.selectedRowsIndeterminate = false;
    this.allRowsSelected = !this.allRowsSelected;
    for (let item of this.items) {
      item.selected = this.allRowsSelected;
    }

    this.selectedRows = this.items.filter(e => e.selected);
    this.onMultiSelectChange.emit(this.selectedRows);
  }

  onCurrentItemPerPageChange(v: number) {
    this.onItemPerPageChange.emit(v);
  }

  pageColumnsChange(value: number) {
    this.currentColumPage = this.currentColumPage + value;

    this.setMultiPageColumnsArray();
  }

  iconLinkClick(item: any, conf: ITableColConfigurations) {
    this.onIconLinkClick.emit({ columnConfig: conf, item: item })
  }

  //Filters
  onSelectChange(e: any) {
    this.selectedFilterKey = e;

    if (this.selectedFilterKey != 'none') {
      this.selectedFilter = this.configuration.filterKeys.filter(f => f.key == this.selectedFilterKey)[0];
      this.selectedFilterValue = '';
      setTimeout(()=> {
        document.getElementById("custom-table-input-filter").focus();
      }, 200);
    }
    else {
      this.selectedFilter = null;
      this.onFilterChangeSubmit();
    }
  }

  btnFilterClick() {
    this.onFilterChangeSubmit();
  }

  private onFilterChangeSubmit() {
    let filter: ITableFilter;

    if (this.selectedFilterKey != 'none') {
      let filterOperator = '';
      let filterValue = this.selectedFilterValue;

      if (this.selectedFilter?.filter != undefined && this.selectedFilter?.filter != 'like') {
        filterOperator = this.selectedFilter.filter;
      }
      else {
        filterOperator = 'like';
        filterValue = '%' + filterValue + '%';
      }
      filter = {filterField: this.selectedFilterKey, filterValue: filterValue, filterOperator: filterOperator};
    }

    this.onFilterChange.emit(filter);
  }
}
