import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFormConfig, IFormConfigSelect } from 'src/custom-component-lib/classes/interfaces';

@Component({
  selector: 'my-select',
  templateUrl: './my-select.component.html',
  styleUrls: ['./my-select.component.scss']
})
export class MySelectComponent  implements OnInit {
  @Input() config: IFormConfig = null;
  @Input() showLabel: boolean = false;
  @Input() formId: string = '';
  @Input() value: any;
  @Input() selectConfig: IFormConfigSelect = null;

  @Output() onChange = new EventEmitter<any>();

  constructor() {

  }

  ngOnInit() {

  }
}


