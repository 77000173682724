import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'work-in-progress',
  templateUrl: './work-in-progress.component.html',
  styleUrls: ['./work-in-progress.component.scss']
})
export class WorkInProgressComponent implements OnInit {
  @Input() title: string = '';
  @Input() hasContainer: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
