import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  @Input() currentImage: string = '';

  @Output() onUpdateImage = new EventEmitter<File>();
  @Output() onRefreshImage = new EventEmitter();

  private imageFile: File = null;

  constructor(private sanitization: DomSanitizer) { }

  ngOnInit(): void {

  }

  selectImageFile() {
    let el = <HTMLInputElement>document.getElementById('image-uploader');
    console.log('selectImageFile', el)
    el.value = null;
    el.click();
  }

  setImageFromFile(event: any) {
    if(!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }

    let mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      // console.log('Image Type not supported!', mimeType);
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.currentImage = <string>reader.result;
      this.imageFile = event.target.files[0];
    }
  }

  updateImage() {
    if (this.imageFile) {
      this.onUpdateImage.emit(this.imageFile);
    }
  }

  refreshImage() {
    this.onRefreshImage.emit();
  }
}
