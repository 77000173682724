import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() image: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() moreButtonLabel: string = '';
  @Input() cssContainer: string = '';
  @Input() cssContainerHover: string = 'hover:bg-blue-300';
  @Input() cssTitle: string = 'text-black';
  @Input() cssDescription: string = 'text-gray-700';

  @Output() onClick = new EventEmitter();
  @Output() onClickMore = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  goToMain() {
    if (this.moreButtonLabel == '') {
      this.goTo();
    }
  }

  goToTitle() {
    if (this.moreButtonLabel != '') {
      this.goTo();
    }
  }

  goTo() {
    this.onClick.emit();
  }

  goToMore() {
    this.onClickMore.emit();
  }
}
