<div *ngIf="config != null">
  <label *ngIf="showLabel" class="bold">{{label}}</label>
  <div class="relative">
    <input [id]="formId" class="custom-input small" [locale]="locale"
         placeholder="{{'Select a date' | translate}}" [ngModel]="value"
         angular-mydatepicker [options]="options" #dateCalendar="angular-mydatepicker"
         (click)="click()"
         (dateChanged)="onChange.emit($event)">
  </div>
</div>
