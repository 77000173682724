import {ISystemUsers} from "./data.model";

export interface Deserializable {
  deserialize(input: any): this;
}

export class IResponse implements Deserializable {
  status: boolean;
  time: string;
  data: any;
  error: any;
  meta?: any;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export class IPagination {
  total: number;
  count: number;
  per_page: number;
  current_page: number;
  total_pages: number;
  links: {
    next?: string;
    previous?: string;
  }
}

export class Login implements Deserializable {
  token: string;
  type: string;
  user: IUser;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export interface IUserSmall {
  uuid: string;
  name: string;
  latest_login: string;
  role: string;
  enabled: boolean;
}

export interface IUser extends IUserSmall{
  email: string;
  email_verified: boolean;
  phone_number: string;
  sms_notification: string;
  updated_at: string;
  created_at: string;
  systems: ISystemUsers[];
  permissions: any;
  one_system?: string;
}
