import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker';
import { TwCustomComponentsModule } from 'src/custom-component-lib/tw-custom-components.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { environment } from 'src/environments/environment';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { HomeComponent } from './components/dashboard/home/home.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { JwtInterceptor, NGXLoaderInterceptorService } from './guards/jwt-interceptor';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqttUrl,
  port: 8083,
  path: '/mqtt',
  protocol: 'ws',
  keepalive: 10
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    TwCustomComponentsModule,
    AngularMyDatePickerModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
  ],
  providers: [{provide: LocationStrategy , useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NGXLoaderInterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
