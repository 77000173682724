<modal [modalId]="modalId" [showSeparators]="false" [showFooterButtons]="false" [normalSize]="false"
       [widthFull]="false" [titleCss]="titleCss" (onClose)="onClose()">
  <div body class="modal-body-details">
    <detail-form [configurations]="configurations" [title]="title" (dataUpdate)="onSubmit($event)" [autoCompletePassword]="false">
      <div footer>
        <div class="flex justify-between mt-4">
          <div class="flex items-start">
            <div *ngIf="showRememberUser" class="flex items-center h-4 md:h-5">
              <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300" required>
            </div>
            <div *ngIf="showRememberUser" class="ml-2 text-xs md:text-sm">
              <label for="remember" class="font-medium text-gray-900">{{rememberUserLabel}}</label>
            </div>
          </div>
          <a *ngIf="showLostPassword" class="text-xs md:text-sm link" (click)="onLostPassword.emit()">{{lostPasswordLabel}}</a>
        </div>
        <div class="mt-4 sm:mt-5">
          <custom-button cssType="btn-primary" [width]="eCustomButtonWidth.full" [isSubmit]="true" [text]="btnText"></custom-button>
        </div>
        <div *ngIf="showRegisterUser" class="text-xs md:text-sm font-medium text-gray-500 text-center mt-3 sm:mt-4">
          {{registerUserLabel}} <a class="link" (click)="onRegisterUser.emit()">{{registerUser2Label}}</a>
        </div>
      </div>
    </detail-form>
  </div>
</modal>
