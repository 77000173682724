<div class="flex items-center">
  <div role="button" tabindex="0" (click)="onBackClick.emit()">
    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" stroke="black" aria-hidden="true">
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
    </svg>
  </div>
  <div class="{{cssText != '' ? cssText : 'text-back'}}" role="button" tabindex="0" (click)="onClick($event)">
    {{backText | translate}}
  </div>
</div>
