<div class="key-pad-main-container {{css}}" [style.background-color]="backgroundColor" [ngClass]="{'fixed-size': fixedSize}">
  <div class="key-pad-container">
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('1')">1</button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('2')">2</button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('3')">3</button>
    <button class="key double key {{keyOtherCSS}}" (click)="onDeletePressed.emit()">
      <div class="image">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" [attr.fill]="keyDeleteColor">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"/></svg>
      </div>
    </button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('4')">4</button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('5')">5</button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('6')">6</button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('7')">7</button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('8')">8</button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('9')">9</button>
    <button class="key double key {{keyOtherCSS}}" (click)="onEnterPressed.emit()">
      <div class="image">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" [attr.fill]="keyEnterColor">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/></svg>
      </div>
    </button>
    <button class="key {{keyOtherCSS}}" (click)="onNumberPressed.emit(keySeparator)">{{keySeparator}}</button>
    <button class="key {{keyNumberCSS}}" (click)="onNumberPressed.emit('0')">0</button>
  </div>
</div>
