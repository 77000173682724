import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AppService, IGeneralPopup} from "./services/app.service";
import {EventService} from "./services/events.service";
import {toggleModal} from "../custom-component-lib/classes/shared-functions";
import {eCustomButtonWidth, ePopupIcon } from 'src/custom-component-lib/classes/enums';
import {AuthenticationService} from "./services/authentication.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'domes';

  ePopupIcon = ePopupIcon;
  eCustomButtonWidth = eCustomButtonWidth;
  apiError: string = '';
  generalError: IGeneralPopup;

  constructor(private appService: AppService,
              private auth: AuthenticationService,
              private translate: TranslateService,
              private eventService: EventService) {
    auth.getApiError().subscribe((value) => {
      this.apiError = value;
    });

    appService.getGeneralPopup().subscribe((value: IGeneralPopup) => {
      this.generalError = value;
    })
  }

  ngOnInit(): void {
    this.setLanguageFromBrowserSettings();
  }

  setLanguageFromBrowserSettings() {
    this.translate.setDefaultLang('en');
    let code = this.getLanguageFromBrowserSettings();
    this.useLanguage(code);
  }

  useLanguage(language: string) {
    this.translate.use(language).subscribe(() => {
      this.eventService.onTranslationsLoaded();
    });
  }

  getLanguageFromBrowserSettings() {
    //   console.log('setLanguageFromBrowserSettings');
    //   let t = navigator.language;
    //   console.log('LANGUAGE = ', t);
    //
    //   if (t.indexOf('it') != -1) {
    //     t = 'it';
    //   }
    //
    //   if (t == 'en' || t == 'de' || t == 'it') {
    //     //OK!
    //   } else {
    //     t = 'en';
    //   }
    //
    //   this.useLanguage(t);
    //   console.log('SET LANGUAGE = ', t);
    // }

    return 'it';
  }

  hidePopupError() {
    toggleModal('api-error-popup', false);
  }

  hideGeneralError() {
    toggleModal('general-error-popup', false);
  }
}

