<div class="font-bold md:text-xl mb-3" [ngClass]="{'flex justify-between items-center mb-1': addButtonPresent}">
  <div>
    <span [ngClass]="{'font-bold text-sm md:text-base mb-0': smallRow}">{{title | translate}}</span>
    <span *ngIf="showCollectionSize && items.length > 0" class="text-primary"> ({{items.length}})</span>
    <span *ngIf="isFiltered" class="font-regular font-12"> - {{'Filter Enabled'}}</span>
  </div>
  <div *ngIf="addButtonPresent">
    <svg class="h-10 w-10 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#2199e8" (click)="onAdd.emit()">
      <path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
    </svg>
  </div>
</div>

<div *ngIf="configuration.filterKeys && configuration.filterKeys.length > 0" class="filter-container">
  <div [ngClass]="{'sm:col-span-6': selectedFilterKey == 'none', 'col-span-3 sm:col-span-2': selectedFilterKey != 'none'}">
    <div>
      <label>{{'Filters List' | translate}}</label>
      <select class="custom-input" [(ngModel)]="selectedFilterKey" (ngModelChange)="onSelectChange($event)">
        <option disabled selected [ngValue]="">{{'Select Filter' | translate}}</option>
        <option [ngValue]="'none'">{{'None' | translate}}</option>
        <option *ngFor="let filter of configuration.filterKeys" [ngValue]="filter.key">{{filter.label | translate}}</option>
      </select>
    </div>
  </div>
  <div *ngIf="selectedFilterKey != 'none'" class="col-span-3 sm:col-span-3">
    <div>
      <label>{{(selectedFilter ? selectedFilter?.label : '-') | translate}}</label>
      <input id="custom-table-input-filter" [type]="'text'" class="custom-input" autofocus="" [placeholder]="(selectedFilter?.placeholder ? selectedFilter?.placeholder : '') | translate"
             [disabled]="!selectedFilter" autocomplete="" [(ngModel)]="selectedFilterValue">
    </div>
  </div>
  <div *ngIf="selectedFilterKey != 'none'" class="col-span-3 sm:col-span-1 flex items-end">
    <button class="btn-filter" (click)="btnFilterClick()" [disabled]="selectedFilterValue == ''">{{'Apply Filter' | translate}}</button>
  </div>
</div>

<div class='overflow-auto w-full rounded-md border' [ngClass]="{'overflow-y-auto overflow-x-hidden': columnsPaginationEnabled}">
  <div class="border-b border-gray-50 shadow">
    <table class="divide-y divide-gray-300 w-full">
      <thead class="bg-gray-100">
      <tr class="text-blue-500 text-xs">
        <th *ngIf="configuration.thumbnailKey != ''" class="thumbnail-header">-</th>
        <td *ngIf="multiSelectEnabled" class="pl-2 sm:pl-3">
          <input id="checkAllRows" type="checkbox" class="custom-checkbox no-label small"
                [ngClass]="{'indeterminate': selectedRowsIndeterminate}"
                [ngModel]="allRowsSelected" [indeterminate]="selectedRowsIndeterminate"
                (ngModelChange)="selectAllClick()">
        </td>
        <th *ngFor="let conf of (columnsPaginationEnabled ? configuration.multiPageCols : configuration.cols); let i = index"
            class="px-2 sm:px-3" [ngClass]="{'py-3 no-int-border-l': (i == 0 && configuration.thumbnailKey == '') || hideHeadersBorder}">
          <div class="flex" [ngClass]="{'justify-center': !configuration.headerAlignToLeft}" [ngStyle]="conf.customStyle ? conf.customStyle : null">
            <span class="block whitespace-nowrap"
                  [ngClass]="{'cursor-pointer hover:underline hover:italic': conf.sortEnabled,
                              '!whitespace-normal': columnsPaginationEnabled}"
                  (click)="conf.sortEnabled && onSortPressed(conf.key)">{{conf.label | translate}}</span>
            <div *ngIf="conf.sortEnabled" [ngSwitch]="sortingHeaderStatus[conf.key]" role="button" class="sort-button float-right ml-1.5" (click)="onSortPressed(conf.key)">
              <svg *ngSwitchCase="eTableSortStatus.none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"/>
              </svg>
              <svg *ngSwitchCase="eTableSortStatus.asc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"/>
              </svg>
              <svg *ngSwitchCase="eTableSortStatus.desc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
              </svg>
            </div>
          </div>
        </th>
        <th *ngIf="configuration.customEnabled && configuration.customImageBase64" class="action-column"></th>
        <th *ngIf="configuration.editEnabled || configuration.deleteEnabled || configuration.downloadEnabled" class="action-column"
            [ngClass]="{'all': configuration.editEnabled && configuration.deleteEnabled && configuration.downloadEnabled}"></th>
        <th *ngIf="columnsPaginationEnabled" class="!border-l-0 px-1 w-8">
          <div class="page-columns-buttons-container">
            <div class="button-container left" [ngClass]="{'disabled': currentColumPage < 2}"
                 (click)="currentColumPage > 1 && pageColumnsChange(-1)">
              <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z"/>
              </svg>
            </div>
            <!-- <div class="separator"></div> -->
            <div class="button-container right" [ngClass]="{'disabled': currentColumPage >= lastColumnPage}"
                 (click)="currentColumPage < lastColumnPage && pageColumnsChange(1)">
              <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path d="M69.8437,43.3876,33.8422,13.3863a6.0035,6.0035,0,0,0-7.6878,9.223l30.47,25.39-30.47,25.39a6.0035,6.0035,0,0,0,7.6878,9.2231L69.8437,52.6106a6.0091,6.0091,0,0,0,0-9.223Z"/>
              </svg>
            </div>
          </div>
        </th>
      </tr>
      </thead>

      <tbody class="bg-white divide-y divide-gray-300">
      <tr *ngFor="let item of items | paginate: { id: paginationID, itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index"
          class="{{smallRow ? 'text-xs' : 'text-sm'}} text-gray-900 hover:bg-blue-100"
          [ngClass]="{'bg-yellow-200': (currentItemSelected == item && markSelected) || (item.selected && multiSelectEnabled && highlightRowOnMultiselect),
                      'hover:bg-yellow-400': item.selected && multiSelectEnabled && highlightRowOnMultiselect}">
        <td *ngIf="configuration.thumbnailKey != ''" class="thumbnail-row"><img role="button" alt=""
                    src="{{item[configuration.thumbnailKey]}}" (click)="onThumbnailSelected.emit(item)"></td>
        <td *ngIf="multiSelectEnabled" class="pl-2 sm:pl-3">
          <input type="checkbox" class="custom-checkbox no-label small"
                [ngModel]="item.selected" (ngModelChange)="multiSelectChange(item, $event)">
        </td>
        <td [attr.role]="rowClickEnabled ? 'button' : ''" *ngFor="let conf of (columnsPaginationEnabled ? configuration.multiPageCols : configuration.cols); let i2 = index"
            class="{{smallRow ? 'py-2' : 'py-4'}} px-2 sm:px-3 break-words"
            [ngClass]="{'no-int-border-l': (i2 == 0 && configuration.thumbnailKey == '') || hideRowsBorder,
                        'text-right': (conf.type == eTableItemType.number || conf.type == eTableItemType.currency || conf.type == eTableItemType.date) && !conf.noDefaultAlignment,
                        'whitespace-nowrap': conf.wordWrapDisabled}"
            [title]="conf.showTooltip ? (conf.tooltipCustomText ? conf.tooltipCustomText : (item | getTableValue: conf)) : ''"
            (click)="conf.iconLink == null && rowClickEnabled && itemSelected(item)">
            <div [ngSwitch]="conf.type">
              <div *ngSwitchCase="eTableItemType.boolean" [ngClass]="{'text-center': !conf.noDefaultAlignment}">
                <input type="checkbox" class="custom-checkbox no-label pointer-events-none" [ngClass]="{'medium': smallRow}"
                       [checked]="item | getTableValue: conf" disabled>
              </div>
              <div *ngSwitchCase="eTableItemType.iconLink" class="flex cursor-default" [ngClass]="{'justify-center': !conf.noDefaultAlignment}">
                <div class="{{smallRow ? 'row-image-small' : 'row-image-normal'}}">
                  <img role="button" [src]="conf.iconLink?.src" (click)="iconLinkClick(item, conf)">
                </div>
              </div>
              <div *ngSwitchCase="eTableItemType.iconStatus" class="flex" [ngClass]="{'justify-center': !conf.noDefaultAlignment}">
                <span class="icon-status"
                      [title]="conf.iconStatus?.showStatusToolip ? (item | getStatusTooltip: conf) : ''"
                      [ngStyle]="{'background-color': item | getStatusColor: conf }"
                      [ngClass]="{'circle': conf.iconStatus?.type == eTableStatusIconType.circle,
                                  'square': conf.iconStatus?.type == eTableStatusIconType.square,
                                  'rounded-square': conf.iconStatus?.type == eTableStatusIconType.roundedSquare,
                                  'add-border': conf.iconStatus?.showBorder}">
                </span>
              </div>
              <div *ngSwitchDefault [ngClass]="{'text-left': conf.textAlign == eTableTextAlign.left,
                                                'text-center': conf.textAlign == eTableTextAlign.center,
                                                'text-right': conf.textAlign == eTableTextAlign.right}">
                {{item | getTableValue: conf : currencySymbol | translate}}
              </div>
            </div>
          </td>
        <td *ngIf="configuration.customEnabled && configuration.customImageBase64" class="px-3">
          <div class="flex justify-center">
            <div role="button" class="{{smallRow ? 'row-image-small' : 'row-image-normal'}} text-red-400" (click)="onCustomClick(item)">
              <img alt="" [src]="configuration.customImageBase64">
            </div>
          </div>
        </td>
        <td *ngIf="configuration.editEnabled || configuration.deleteEnabled || configuration.downloadEnabled" class="px-3">
          <div class="flex justify-center"
               [ngClass]="{'justify-between': configuration.editEnabled && configuration.deleteEnabled && configuration.downloadEnabled}">
            <div *ngIf="configuration.editEnabled" role="button" class="{{smallRow ? 'row-image-small' : 'row-image-normal'}} text-blue-400" (click)="onEditClick(item)">
              <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </div>
            <div *ngIf="configuration.deleteEnabled" role="button" class="{{smallRow ? 'row-image-small' : 'row-image-normal'}} text-red-400" (click)="onDeleteClick(item)">
              <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </div>
            <div *ngIf="configuration.downloadEnabled" role="button" class="{{smallRow ? 'row-image-small' : 'row-image-normal'}} text-red-400" (click)="onDownloadClick(item)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"/>
              </svg>
            </div>
          </div>
        </td>
        <td *ngIf="columnsPaginationEnabled" class="!border-l-0"></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="pagination-container">
  <div class="pagination">
    <pagination-controls id="{{paginationID}}" class="custom-pagination" *ngIf="totalItems > 0" [previousLabel]="previousBtnLbl" [nextLabel]="nextBtnLbl"
                         [responsive]="true" (pageChange)="handlePageChange($event)"
    ></pagination-controls>

    <div *ngIf="showTotalItems" class="total-items">
      <span>Tot. {{totalItems}}</span>
    </div>

    <div *ngIf="showItemsPerPage" class="item-per-page">
      <select class="custom-input" [(ngModel)]="itemsPerPage" (ngModelChange)="onCurrentItemPerPageChange($event)">
        <option disabled selected value>{{'Items per Page' | translate}}</option>
        <option *ngFor="let number of numbersToLoop; let i = index" [ngValue]="number">{{number}}</option>
<!--        <option *ngFor="let _ of [].constructor(40); let i = index" [ngValue]="i + 1">{{i + 1}}</option>-->
      </select>
    </div>
  </div>
</div>


