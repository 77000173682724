import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @Input() imgSource: string = '';
  @Input() imgAlt: string = "";
  @Input() link: string = "";
  @Input() text: string = "";
  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
