<div class="img-preview">
  <div class="flex justify-center">
    <div *ngIf="currentImage == ''" class="w-1/2" (click)="selectImageFile()">
      <svg viewBox="0 0 512 512">
        <path d="M152 120c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48S178.5 120 152 120zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM463.1 409.3l-136.8-185.9C323.8 218.8 318.1 216 312 216c-6.113 0-11.82 2.768-15.21 7.379l-106.6 144.1l-37.09-46.1c-3.441-4.279-8.934-6.809-14.77-6.809c-5.842 0-11.33 2.529-14.78 6.809l-75.52 93.81c0-.0293 0 .0293 0 0L47.99 96c0-8.822 7.178-16 16-16h384c8.822 0 16 7.178 16 16V409.3z"/>
      </svg>
    </div>
    <div *ngIf="currentImage != ''">
      <img role="button" alt="" [src]="currentImage" (click)="selectImageFile()">
    </div>
    <input id='image-uploader' type='file' (change)="setImageFromFile($event)" hidden/>
  </div>
  <div class="image-buttons">
    <div class="image-button" (click)="updateImage()">
      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
        <path d="M22.5 32.35V13.8L16.5 19.8L14.35 17.65L24 8L33.65 17.65L31.5 19.8L25.5 13.8V32.35ZM11 40Q9.8 40 8.9 39.1Q8 38.2 8 37V29.85H11V37Q11 37 11 37Q11 37 11 37H37Q37 37 37 37Q37 37 37 37V29.85H40V37Q40 38.2 39.1 39.1Q38.2 40 37 40Z"/>
      </svg>
    </div>
    <div class="image-button" (click)="refreshImage()">
      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
        <path d="M24 40Q17.35 40 12.675 35.325Q8 30.65 8 24Q8 17.35 12.675 12.675Q17.35 8 24 8Q28.25 8 31.45 9.725Q34.65 11.45 37 14.45V8H40V20.7H27.3V17.7H35.7Q33.8 14.7 30.85 12.85Q27.9 11 24 11Q18.55 11 14.775 14.775Q11 18.55 11 24Q11 29.45 14.775 33.225Q18.55 37 24 37Q28.15 37 31.6 34.625Q35.05 32.25 36.4 28.35H39.5Q38.05 33.6 33.75 36.8Q29.45 40 24 40Z"/>
      </svg>
    </div>
  </div>
</div>
