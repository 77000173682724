import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'number-key-pad',
  templateUrl: './number-key-pad.component.html',
  styleUrls: ['./number-key-pad.component.scss']
})
export class NumberKeyPadComponent implements OnInit {
  @Input() css: string = '';
  @Input() backgroundColor: string = 'whitesmoke';
  @Input() keyDeleteColor: string = 'red';
  @Input() keyEnterColor: string = 'green';
  @Input() keyNumberCSS: string = 'btn btn-outline-dark';
  @Input() keyOtherCSS: string = 'btn btn-outline-secondary';
  @Input() keySeparator: string = ',';
  @Input() fixedSize: boolean = true;

  @Output() onNumberPressed = new EventEmitter<string>();
  @Output() onDeletePressed = new EventEmitter();
  @Output() onEnterPressed = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
