import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { IDetailsFormDataUpdate, IDetailsFormInputChange, IDetailsFormSelectChange, IGroupDetailFormConfiguration, IGroupDetailsFormDataUpdate, IGroupSubmit } from '../classes/interfaces';
import { FormGroup } from '@angular/forms';
import { DetailFormComponent } from '../detail-form/detail-form.component';
import { eCustomButtonWidth } from '../classes/enums';

@Component({
  selector: 'group-detail-form',
  templateUrl: './group-detail-form.component.html',
  styleUrls: ['./group-detail-form.component.scss']
})
export class GroupDetailFormComponent implements OnInit {
  @Input() mainTitle: string = '';
  @Input() configurations: IGroupDetailFormConfiguration[] = [];
  @Input() showGroupBox: boolean = true;
  @Input() item: any = null;
  @Input() isReadOnly: boolean = false;
  @Input() submitText = 'Submit';
  @Input() markOnTouched: boolean = false;
  @Input() isNew: boolean = false;
  @Input() autoCompletePassword: boolean = false;
  @Input() selectsLists: any[] = [];
  @Input() excludeDisabledItemsOnUpdate: boolean = true;
  @Input() showUpDownNumberAlways: boolean = false;
  @Input() hideUpDownNumberAlways: boolean = false;
  @Input() removeHiddenControls: boolean = true;
  @Input() small: boolean = false;
  @Input() autofocus: boolean = true;
  @Input() showFooter: boolean = true;

  @Output() submit = new EventEmitter<IGroupSubmit>();
  @Output() dataUpdate = new EventEmitter<IGroupDetailsFormDataUpdate>();
  @Output() selectChanged = new EventEmitter<IDetailsFormSelectChange>();
  @Output() inputChanged = new EventEmitter<IDetailsFormInputChange>();

  @ViewChildren(DetailFormComponent) detailsForm!: QueryList<DetailFormComponent>;

  eCustomButtonWidth = eCustomButtonWidth;

  constructor() { }

  ngOnInit() {
  }

  onSubmit(v: FormGroup, key: string) {
    this.submit.emit({formGroup: v, groupKey: key})
  }

  onSelectChange(v: IDetailsFormSelectChange) {
    this.selectChanged.emit(v);
  }

  onInputChange(v: IDetailsFormInputChange) {
    this.inputChanged.emit(v);
  }

  save() {
    let itemsOut: IGroupDetailsFormDataUpdate;
    let dataOK: boolean = true;
    for(let detailForm of this.detailsForm) {
      let data = detailForm.onSubmit();
      if (data) {
        if (itemsOut) {
          itemsOut.items = {...itemsOut.items, ...data.items}; // Object.assign({}, itemsOut, items);
        }
        else {
          itemsOut = data;
        }
      }
      else {
        dataOK = false;
      }
    }
    if (itemsOut) {
      itemsOut.dataOK = dataOK;
      this.dataUpdate.emit(itemsOut);
    }
  }
}
