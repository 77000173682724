<div class="main-container">
  <div *ngIf="!isLoggedIn" class="not-logged-in-container">
    <img alt="" src="assets/images/logo.jpeg" class="border rounded-xl border-gray-300 shadow-xl shadow-gray-400">
    <div class="text-white absolute inset-x-0 bottom-16 text-center w-full">
      <!-- <span class="block text-3xl font-bold text-blue-400">{{plantInformation.name}}</span>
      <span class="text-xs italic">{{'Vrs. ' + plantInformation.software_version}}</span> -->
    </div>
  </div>

  <ng-container *ngIf="isLoggedIn">

  </ng-container>
</div>


