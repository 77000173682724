import { DatePipe } from '@angular/common';
import {NgZone} from '@angular/core';
import { IMyDate, IMyDateModel } from '@nodro7/angular-mydatepicker';
import {Observable, OperatorFunction} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {parse} from 'date-fns'

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
  return (value !== undefined && value !== null) ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

export function regExpEscape(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
  return element && element.className && element.className.split &&
    element.className.split(/\s+/).indexOf(className) >= 0;
}

/**
 * Force a browser reflow
 * @param element element where to apply the reflow
 */
export function reflow(element: HTMLElement) {
  return (element || document.body).getBoundingClientRect();
}

/**
 * Creates an observable where all callbacks are executed inside a given zone
 *
 * @param zone
 */
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return (source) => {
    return new Observable(observer => {
      const onNext = (value: T) => zone.run(() => observer.next(value));
      const onError = (e: any) => zone.run(() => observer.error(e));
      const onComplete = () => zone.run(() => observer.complete());
      return source.subscribe(onNext, onError, onComplete);
    });
  };
}

export function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export enum eDateFormat{
  DDMMAAAA,
  MMDDAAAA,
  AAAAMMDD
}

export function capitalize(v: string) {
  let v2 = v.toLowerCase();

  return v2.charAt(0).toUpperCase() + v2.slice(1);
}

export function formatDecimal(v: number): string {
  return String((Math.round(v * 100) / 100).toFixed(2));
}

export function generateUUID() {
  return uuidv4();
}

export function gotoBottom(delay: number = 100) {
  setTimeout(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, delay);
}

export function copyObject(v: any, useJSONParser: boolean = false): any {
  if (v) {
    if (useJSONParser) {
      return JSON.parse(JSON.stringify(v));
    }
    else {
      return Object.assign({}, v);
    }
  }

  return v;
}

export class dateUtils {
  static formatDate(date: string, fromFormat: eDateFormat, toFormat: eDateFormat, isForNgbDatePicker: boolean = false, fromSeparator: string = '-', toSeparator: string = '-') {
    if (date) {
      if (date.includes('T')) {
        date = date.split('T')[0];
      }
      else {
        date = date.split(' ')[0];
      }

      let da = date.split(fromSeparator);
      let ret: any;
      let dd: string = '';
      let mm: string = '';
      let aa: string = '';

      switch (fromFormat) {
        case eDateFormat.AAAAMMDD:
          dd = da[2];
          mm = da[1];
          aa = da[0];
          break;
        case eDateFormat.MMDDAAAA:
          dd = da[1];
          mm = da[0];
          aa = da[2];
          break;
        case eDateFormat.DDMMAAAA:
          dd = da[0];
          mm = da[1];
          aa = da[2];
          break;
      }

      if (isForNgbDatePicker) {
        ret = {year: Number(aa), month: Number(mm), day: Number(dd)};
      } else {
        switch (toFormat) {
          case eDateFormat.AAAAMMDD:
            ret = aa + toSeparator + mm + toSeparator + dd;
            break;
          case eDateFormat.MMDDAAAA:
            ret = mm + toSeparator + dd + toSeparator + aa;
            break;
          case eDateFormat.DDMMAAAA:
            ret = dd + toSeparator + mm + toSeparator + aa;
            break;
        }
      }

      return ret;
    }

    return null;
  }

  static unixTimeToString(unixTime: number, toFormat: eDateFormat = eDateFormat.AAAAMMDD, dateSeparator: string = '-', timeSeparator: string = ':', middleSeparator: string = ' ') {
    let date = new Date(unixTime * 1);

    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    let formattedTime = '';
    switch (toFormat) {
      case eDateFormat.AAAAMMDD:
        formattedTime = String(year) + dateSeparator + padNumber(month) + dateSeparator + padNumber(day);
        break;
      case eDateFormat.MMDDAAAA:
        formattedTime = padNumber(month) + dateSeparator + String(year) + dateSeparator + padNumber(day);
        break;
      case eDateFormat.DDMMAAAA:
        formattedTime = padNumber(day) + dateSeparator + padNumber(month) + dateSeparator + String(year);
        break;
    }

    formattedTime += middleSeparator + padNumber(hours) + ':' + padNumber(minutes) + ':' + padNumber(seconds);

    return formattedTime;
  }

  static formatTimeForNGBDatePicker(date: string) {
    if (date) {
      if (date.includes('T')) {
        date = date.split('T')[1].split('.')[0];
      }
      let da = date.split(':');
      let ret: any;
      let hh: string = da[0];
      let mm: string = da[1];
      let ss: string = da[2];

      return {hour: Number(hh), minute: Number(mm), second: Number(ss)};
    }

    return null;
  }


  static ngbDateFormatToString(date: any, toFormat: eDateFormat, separator: string = '-') {
    let ret = '';

    switch (toFormat) {
      case eDateFormat.AAAAMMDD:
        ret = date.year + separator + padNumber(date.month) + separator + padNumber(date.day);
        break;
      case eDateFormat.MMDDAAAA:
        ret = padNumber(date.month) + separator + padNumber(date.day) + separator + date.year;
        break;
      case eDateFormat.DDMMAAAA:
        ret = padNumber(date.day) + separator + padNumber(date.month) + separator + date.year;
        break;
    }

    return ret;
  }

  static ngbTimeFormatToString(time: any, separator: string = ':', showSeconds: boolean = false) {
    let ret = '';

    if (showSeconds) {
      ret = padNumber(time.hour) + separator + padNumber(time.minute) + separator + padNumber(time.second);
    }
    else {
      ret = padNumber(time.hour) + separator + padNumber(time.minute);
    }

    return ret;
  }

  static ngbDateSingleFromString(v: string, dateFormat: string = 'dd/MM/yyyy') {
    let d = parse(v, dateFormat, new Date());

    let ret: IMyDateModel = {
      isRange: false,
      singleDate: {
        date: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
      }
    }

    return ret;
  }

  static ngbDateRangeFromString(vFrom: string, vTo: string, dateFormat: string = 'dd/MM/yyyy') {
    let d1 = parse(vFrom, dateFormat, new Date());
    let d2 = parse(vTo, dateFormat, new Date());
    let ret: IMyDateModel = {
      isRange: true,
      dateRange: {
        beginDate: { year: d1.getFullYear(), month: d1.getMonth() + 1, day: d1.getDate() },
        endDate: { year: d2.getFullYear(), month: d2.getMonth() + 1, day: d2.getDate() }
      }
    }

    return ret;
  }

  static ngbGetCurrentMonth() {
    let d: Date = new Date();

    return dateUtils.ngbGetDefaultDateModel( { year: d.getFullYear(), month: d.getMonth() + 1, day: 1} )
  }

  static ngbGetMonthString(date: IMyDate, separator: string = '/') {
    return padNumber(date.month) + separator + date.year;
  }

  static ngbGetDefaultDateModel(date: IMyDate) {
    let ret: IMyDateModel = {
      isRange: false,
      singleDate: {
        date: date
      }
    }

    return ret;
  }

  static calculateAge(date: string) { // birthday is a date
    let birthDay = new Date(date);
    let now = new Date();
    let diff =(now.getTime() - birthDay.getTime()) / 1000;
    diff /= (60 * 60 * 24);

    return Math.abs(Math.round(diff/365.25));
  }

  static getTodayForNGBDatePicker() {
    let date = new Date();

    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
  }

  static getDate(value: string, separator: string = '-', dateFormat: string = 'dd/MM/yyyy') {
    let code = navigator.language;
    let date = new Date();

    if (value != '') {
      date = parse(value, dateFormat, new Date());
    }

    return date.toLocaleDateString(code, { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, separator);
  }

  static getTime(value: string, showSeconds: boolean = false, timezoneUTC: boolean = false) {
    let code = navigator.language;

    let options = {};
    if (showSeconds) {
      if (timezoneUTC) {
        options = { timeZone: 'UTC' };
      }

      return new Date(value).toLocaleTimeString(code, options);
    }
    else {
      let options: any = { hour: 'numeric', minute: '2-digit' };
      if (timezoneUTC) {
        options = { hour: 'numeric', minute: '2-digit', timeZone: 'UTC' };
      }

      return new Date(value).toLocaleTimeString(code, options);
    }
  }

  static getTimeFromSeconds(seconds: number) {
    let hours = Math.floor(seconds / 3600);
    let min = Math.floor((seconds - (hours * 3600)) / 60);
    let sec = seconds - (hours * 3600) - (min * 60);

    return  padNumber(hours) + ':' + padNumber(min) + ':' + padNumber(sec);
  }

  static getDateTime(value: string, separator: string = '-', showSeconds: boolean = false, timezoneUTC: boolean = false) {
    return this.getDate(value, separator) + ' ' + this.getTime(value, showSeconds, timezoneUTC);
  }

  static getDateTimeNow(separator: string = '-', showSeconds: boolean = true, addDay: number = 0) {
    let today = new Date();
    today.setDate(today.getDate() + addDay);

    let date = padNumber(today.getDate())  + separator + padNumber((today.getMonth() + 1)) + separator + today.getFullYear();
    let time = today.getHours() + ":" + today.getMinutes();

    let ret = date + ' ' + time;
    if (showSeconds) {
      ret += ":" + today.getSeconds()
    }

    return ret;
  }
}
