import { NgModule } from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common'

import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CustomButtonComponent } from './custom-button/custom-button.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { DetailFormComponent } from './detail-form/detail-form.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { NotificationComponent } from "./notification/notification.component";
import { ModalComponent } from "./modal/modal.component";
import {CardComponent} from "./card/card.component";
import {ModalLoginComponent} from "./modal-login/modal-login.component";
import {ToggleButtonComponent} from "./toggle-button/toggle-button.component";
import {MatrixComponent} from "./matrix/matrix.component";
import {DetailsComponent} from "./details/details.component";
import {ModalRegisterComponent} from "./modal-register/modal-register.component";
import {TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ModalForgottenPasswordComponent} from "./modal-forgotten-password/modal-forgotten-password.component";
import {ModalResetPasswordComponent} from "./modal-reset-password/modal-reset-password.component";
import {RouterModule} from "@angular/router";
import {MobilePageHeaderComponent} from "./mobile-page-header/mobile-page-header.component";
import {BackHeaderComponent} from "./back-header/back-header.component";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {TabComponent} from "./tab/tab.component";
import {SearchBarComponent} from "./search-bar/search-bar.component";
import {NumberKeyPadComponent} from "./number-key-pad/number-key-pad.component";
import {MyDraggingDirective} from "./directives/my-dragging.directive";
import {ContextMenuComponent} from "./context-menu/context-menu.component";
import {ContextMenuItemComponent} from "./context-menu/context-menu-item/context-menu-item.component";
import {SvgImageComponent} from "./svg-image/svg-image.component";
import {ImageUploadComponent} from "./image-upload/image-upload.component";
import {WorkInProgressComponent} from './work-in-progress/work-in-progress.component';
import { MultiFilterComponent } from './multi-filter/multi-filter.component';
import { MyInputComponent } from './objects-wrapper/my-input/my-input.component';
import { MySelectComponent } from './objects-wrapper/my-select/my-select.component';
import { MyDateSelectorComponent } from './objects-wrapper/my-date-selector/my-date-selector.component';
import { MyMonthSelectorComponent } from './objects-wrapper/my-month-selector/my-month-selector.component';
import { MyCheckBoxComponent } from './objects-wrapper/my-check-box/my-check-box.component';
import { GetTableValuePipe } from './pipe/gettablevaluepipe';
import { GetStatusColorPipe } from './pipe/getstatuscolor';
import { GetStatusTooltipPipe } from './pipe/getstatustooltip';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { GroupDetailFormComponent } from './group-detail-form/group-detail-form.component';
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker';
import { SafePipe } from './pipe/safepipe';
import { FileUploadComponent } from './file-upload/file-upload.component';

@NgModule({
  declarations: [
    MyDraggingDirective,
    SafePipe,
    GetTableValuePipe,
    GetStatusColorPipe,
    GetStatusTooltipPipe,

    CustomButtonComponent,
    CustomTableComponent,
    DetailFormComponent,
    MenuItemComponent,
    NotificationComponent,
    ModalComponent,
    ModalLoginComponent,
    ModalRegisterComponent,
    ModalForgottenPasswordComponent,
    ModalResetPasswordComponent,
    CardComponent,
    ToggleButtonComponent,
    MatrixComponent,
    DetailsComponent,
    MobilePageHeaderComponent,
    BackHeaderComponent,
    TabComponent,
    SearchBarComponent,
    NumberKeyPadComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SvgImageComponent,
    ImageUploadComponent,
    WorkInProgressComponent,
    MultiFilterComponent,
    MyInputComponent,
    MySelectComponent,
    MyDateSelectorComponent,
    MyMonthSelectorComponent,
    MyCheckBoxComponent,
    GroupDetailFormComponent,
    FileUploadComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    AngularMyDatePickerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    NgxUiLoaderModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    MyDraggingDirective,
    SafePipe,
    GetTableValuePipe,
    GetStatusColorPipe,
    GetStatusTooltipPipe,

    CustomButtonComponent,
    CustomTableComponent,
    DetailFormComponent,
    MenuItemComponent,
    NotificationComponent,
    ModalComponent,
    ModalLoginComponent,
    ModalRegisterComponent,
    ModalForgottenPasswordComponent,
    ModalResetPasswordComponent,
    CardComponent,
    ToggleButtonComponent,
    MatrixComponent,
    DetailsComponent,
    MobilePageHeaderComponent,
    BackHeaderComponent,
    TabComponent,
    SearchBarComponent,
    NumberKeyPadComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SvgImageComponent,
    ImageUploadComponent,
    WorkInProgressComponent,
    MultiFilterComponent,
    MyInputComponent,
    MySelectComponent,
    MyDateSelectorComponent,
    MyMonthSelectorComponent,
    MyCheckBoxComponent,
    GroupDetailFormComponent,
    FileUploadComponent
  ],
  providers: [DecimalPipe, SafePipe, GetTableValuePipe,
              GetStatusColorPipe, GetStatusTooltipPipe]
})
export class TwCustomComponentsModule {
}

export function LibLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
