import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IFormConfig, IFormConfigInput, IFormObjectCurrentValue } from '../classes/interfaces';
import { eFilterType } from '../classes/enums';
import { ActiveView, AngularMyDatePickerDirective, CalAnimation, DefaultView, IAngularMyDpOptions, IMyCalendarViewChanged, IMyDate, IMyDateModel, IMyInputFieldChanged } from '@nodro7/angular-mydatepicker';
import { copyObject, dateUtils } from '../classes/utility';
import { MyDateSelectorComponent } from '../objects-wrapper/my-date-selector/my-date-selector.component';
import { MyMonthSelectorComponent } from '../objects-wrapper/my-month-selector/my-month-selector.component';

@Component({
  selector: 'multi-filter',
  templateUrl: './multi-filter.component.html',
  styleUrls: ['./multi-filter.component.scss']
})
export class MultiFilterComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() config: IFormConfig[] = [];
  @Input() setFocusOnStart: boolean = false;
  @Input() setFocusOnFilterChange: boolean = true;
  @Input() filterTitle: string = '';
  @Input() showSelectedFilters: boolean = true;
  @Input() showAllSearchedSelectedFilters: boolean = true;
  @Input() showValuesOnSelectedFilters: boolean = true;
  @Input() showNoFiltersMessage: boolean = true;

  @Output() onFilterChanged = new EventEmitter<IFormConfig[]>();
  @Output() onFilterRemoved = new EventEmitter<IFormConfig[]>();

  @ViewChild('dateCalendar') myDateSelector: MyDateSelectorComponent;
  @ViewChild('dateCalendarFrom') myDateSelectorFrom: MyDateSelectorComponent;
  @ViewChild('dateCalendarTo')  myDateSelectorTo: MyDateSelectorComponent;
  @ViewChild('monthCalendar') myMonthSelector: MyMonthSelectorComponent;
  @ViewChild('monthCalendarFrom') myMonthSelectorFrom: MyMonthSelectorComponent;
  @ViewChild('monthCalendarTo') myMonthSelectorTo: MyMonthSelectorComponent;

  filterType = eFilterType;
  filters: IFormConfig[] = [];

  selectedName: string = '';
  selectedObjectId: string = '';
  selectedFilter: IFormConfig = null;

  showDefaultLabel: boolean = false;
  objIdFixedPart: string = 'filter_obj_';

  dateLocale: string = 'it';

  constructor(private ngZone: NgZone,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setConfig();
  }

  ngAfterContentChecked() : void {
    this.cdRef.detectChanges();
  }

  //Private functions
  private setConfig() {
    for (let item of this.config) {
      if (item.current) {
        item.current.filterOK = true;
      }
    }

    this.setCurrentFilter(true);

    this.setFiltersArray();
  }

  private setFocusOnCurrentElement() {
    if (this.setFocusOnFilterChange && this.selectedFilter.current == null) {
      setTimeout(() => {
        switch (this.selectedFilter.type) {
          case eFilterType.dateSingle:
            this.myDateSelector.open();
            break;
          case eFilterType.dateFromTo:
            this.myDateSelectorFrom.open();
            break;
          case eFilterType.monthYearSingle:
            this.myMonthSelector.open()
            break;
          case eFilterType.monthYearFromTo:
            this.myMonthSelectorFrom.open();
            break;
          default:
            let from = '';
            if (this.selectedFilter.type == eFilterType.multiInput ||
                this.selectedFilter.type == eFilterType.multiSelect) {
              from = '_from';
            }
            let objId: string = this.objIdFixedPart + this.selectedName + from;
            let el = document.getElementById(objId);
            if (el) {
              el.focus();
            }
            break;
        }
      }, 100)
    }
  }

  private setCurrentFilter(onStart: boolean = false) {
    if (onStart) {
      this.selectedFilter = copyObject(this.config[0]);
      this.selectedName = this.selectedFilter.name;
    }
    else {
      this.selectedFilter = copyObject(this.config.filter(e => e.name == this.selectedName)[0]);
    }

    if (this.selectedFilter.type == eFilterType.multiCheckbox && this.selectedFilter.current == null) {
      this.setDefaultMultiCheckBoxValues();
    }

    switch (this.selectedFilter.type) {
      case eFilterType.dateFromTo:
      case eFilterType.monthYearFromTo:
      case eFilterType.multiInput:
      case eFilterType.multiSelect:
        this.showDefaultLabel = false;
        break;
      default:
        this.showDefaultLabel = true;
        if (this.selectedFilter.type == eFilterType.checkbox && this.selectedFilter.current == null) {
          this.selectedFilter.current = { value : false};
        }
        break;
    }

    if (!(onStart && !this.setFocusOnStart)) this.setFocusOnCurrentElement();
  }

  private setCurrentValue(v: IFormObjectCurrentValue) {
    if (this.selectedFilter.current == null) {
      this.selectedFilter.current = {};
    }

    this.selectedFilter.current = { ...this.selectedFilter.current, ...v };

    console.log('setCurrentValue', this.selectedFilter.current)
  }

  private setFiltersArray(removed: boolean = false) {
    this.filters = this.config.filter(e => e.current != null &&
      (this.showAllSearchedSelectedFilters ? e.current.filterOK : e.current.searched));

    if (removed) {
      this.onFilterRemoved.emit(this.filters);
    }
    else {
      this.onFilterChanged.emit(this.filters);
    }
  }

  private verifyCurrentFilter() {
    let ret = false;

    switch (this.selectedFilter.type) {
      case eFilterType.dateSingle:
        if (!this.myDateSelector.isDateValid()
            && this.selectedFilter.current?.date) this.selectedFilter.current.date = null;
        break;
      case eFilterType.monthYearSingle:
        if (!this.myMonthSelector.isDateValid()
            && this.selectedFilter.current?.date) this.selectedFilter.current.date = null;
        break;
      case eFilterType.dateFromTo:
        if (!this.myDateSelectorFrom.isDateValid()
            && this.selectedFilter.current?.dateFrom) this.selectedFilter.current.dateFrom = null;
        if (!this.myDateSelectorTo.isDateValid()
            && this.selectedFilter.current?.dateTo) this.selectedFilter.current.dateTo = null;
        break;
      case eFilterType.monthYearFromTo:
        if (!this.myMonthSelectorFrom.isDateValid()
            && this.selectedFilter.current?.dateFrom) this.selectedFilter.current.dateFrom = null;
        if (!this.myMonthSelectorTo.isDateValid()
            && this.selectedFilter.current?.dateTo) this.selectedFilter.current.dateTo = null;
        break;
      default:
        break;
    }

    if (this.selectedFilter.current) {
      switch (this.selectedFilter.type) {
        case eFilterType.input:
        case eFilterType.select:
          ret = this.selectedFilter.current.value != '';
          break;
        case eFilterType.checkbox:
          ret = true;
          break;
        case eFilterType.multiInput:
        case eFilterType.multiSelect:
            ret = this.selectedFilter.current.valueFrom != null &&
                  this.selectedFilter.current.valueTo != null;
            break;
        case eFilterType.dateSingle:
        case eFilterType.monthYearSingle:
          ret = this.selectedFilter.current.date?.singleDate?.date != null;
          break;
        case eFilterType.dateFromTo:
        case eFilterType.monthYearFromTo:
          ret = this.selectedFilter.current.dateFrom?.singleDate?.date != null &&
                this.selectedFilter.current.dateTo?.singleDate?.date != null;
          break;
        case eFilterType.multiCheckbox:
          ret = this.selectedFilter.current.values?.length > 0;
          break;
        default:
          break;
      }

      // console.log('verifyCurrentFilter', this.selectedFilter, ret)

      this.selectedFilter.current.searched = false;
      this.selectedFilter.current.filterOK = ret;
      this.setCurrentFilterDescription();
    }

    return ret
  }

  private setCurrentFilterDescription() {
    let v: string = '';
    if (this.selectedFilter.current.filterOK) {
      switch (this.selectedFilter.type) {
        case eFilterType.input:
          v = this.selectedFilter.current.value as string;
          break;
        case eFilterType.select:
          v = this.selectGetValueFromKeyForCurrentFilter(this.selectedFilter.current.value as string);
          break;
        case eFilterType.checkbox:
          let vFalse = this.selectedFilter.checkBox?.labelFalse != '' ? this.selectedFilter.checkBox?.labelFalse : 'No';
          let vTrue = this.selectedFilter.checkBox?.labelTrue != '' ? this.selectedFilter.checkBox?.labelTrue : 'Si';
          v = this.selectedFilter.current.value ? vTrue : vFalse;
          break;
        case eFilterType.multiInput:
          v = this.selectedFilter.current.valueFrom + ' - ' + this.selectedFilter.current.valueTo;
          break;
        case eFilterType.multiSelect:
          v = this.selectGetValueFromKeyForCurrentFilter(this.selectedFilter.current.valueFrom as string) + ' - ' +
              this.selectGetValueFromKeyForCurrentFilter(this.selectedFilter.current.valueTo as string);
          break;
        case eFilterType.dateSingle:
          v = this.selectedFilter.current.date.singleDate.formatted;
          break;
        case eFilterType.monthYearSingle:
          v = dateUtils.ngbGetMonthString(this.selectedFilter.current.date.singleDate.date);
          this.selectedFilter.current.date.singleDate.formatted = v;
          break;
        case eFilterType.dateFromTo:
          v = this.selectedFilter.current.dateFrom.singleDate.formatted + ' - ' +
              this.selectedFilter.current.dateTo.singleDate.formatted;
          break;
        case eFilterType.monthYearFromTo:
          let d1 = dateUtils.ngbGetMonthString(this.selectedFilter.current.dateFrom.singleDate.date);
          let d2 = dateUtils.ngbGetMonthString(this.selectedFilter.current.dateTo.singleDate.date);
          this.selectedFilter.current.dateFrom.singleDate.formatted = d1;
          this.selectedFilter.current.dateTo.singleDate.formatted = d2;
          v = d1 + ' - ' + d2;
          break;
        case eFilterType.multiCheckbox:
          let c: number = 0
          let selLen: number = this.selectedFilter.current.values.filter(e => e).length;
          for (let value of this.selectedFilter.current.values) {
            if (value) {
              let item = this.selectedFilter.checkBox.list[c];
              v += item + (c < selLen ? ' - ' : '');
            }
            c++;
          }
          break;
        default:
          break;
      }
    }

    this.selectedFilter.current.description = v;
  }

  private setDefaultMultiCheckBoxValues() {
    this.selectedFilter.current = { values: [] };
    for (let item of this.selectedFilter.checkBox?.list) {
      this.selectedFilter.current.values.push(false);
    }
  }

  private selectGetValueFromKeyForCurrentFilter(value: string) {
    let list = this.selectedFilter.select[0]?.list;
    let k = this.selectedFilter.select[0]?.key;
    let v = this.selectedFilter.select[0]?.value;

    return list.filter(e => e[k] == value)[0][v];
  }

  //Public functions
  openCalendar(type: eFilterType, from: boolean = false) {
    switch (type) {
      case eFilterType.monthYearSingle:
        this.myMonthSelector.open();
        break;
      case eFilterType.monthYearFromTo:
        if (from) {
          this.myMonthSelectorFrom.open();
        }
        else {
          this.myMonthSelectorTo.open();
        }
        break;
      default:
        break;
    }
  }

  setFiltersSearched() {
    for(let filter of this.config) {
      if (filter.current) {
        filter.current.searched = true;
      }
    }

    this.setFiltersArray();
  }

  resetAllFilters() {
    for(let filter of this.config) {
      filter.current = null;
    }
    this.selectedFilter.current = null;

    this.setCurrentFilter(true);
    this.setFiltersArray(true);
  }

  //Obj Events
  onSelectFilterChange(name: string) {
    this.setCurrentFilter();
  }

  onApplyFilter() {
    this.verifyCurrentFilter();
    this.config.filter(e => e.name == this.selectedName)[0].current = copyObject(this.selectedFilter.current);
    this.setFiltersArray();

    // console.log('onApplyFilter', this.config.filter(e => e.name == this.selectedName)[0], this.selectedFilter)
  }

  onResetFilter() {
    this.setCurrentFilter();
    this.setFiltersArray();
  }

  gotoFilter(filterItem: IFormConfig) {
    console.log('gotoFilter', filterItem)
    this.selectedName = filterItem.name;
    this.setCurrentFilter();
  }

  onCloseFilterClicked(filterItem: IFormConfig) {
    this.config.filter(e => e.name == filterItem.name)[0].current = null;
    this.selectedFilter.current = null;

    this.setCurrentFilter();
    this.setFiltersArray(true);
  }

  onInputOrSelectChange(value: string | boolean, from: boolean = false) {
    switch (this.selectedFilter.type) {
      case eFilterType.input:
      case eFilterType.select:
      case eFilterType.checkbox:
        this.setCurrentValue({ value: value });
        break;
      case eFilterType.multiInput:
      case eFilterType.multiSelect:
        if (from) {
          this.setCurrentValue({ valueFrom: value as string });
        }
        else {
          this.setCurrentValue({ valueTo: value as string });
        }
        break;
      default:
        break;
    }
  }

  onDateChanged(e: IMyDateModel, from: boolean = false) {
    switch (this.selectedFilter.type) {
      case eFilterType.dateSingle:
      case eFilterType.monthYearSingle:
        // console.log('onDateChanged', e)
        this.setCurrentValue({ date: e });
        break;
      case eFilterType.dateFromTo:
      case eFilterType.monthYearFromTo:
        if (from) {
          this.setCurrentValue({ dateFrom: e });
          if (this.selectedFilter.current?.dateTo == null) {
            if (this.selectedFilter.type == eFilterType.dateFromTo) {
              this.myDateSelectorTo.open();
            }
            else {
              this.myMonthSelectorTo.open();
            }
          }
        }
        else {
          this.setCurrentValue({ dateTo: e });
        }
        break;
      default:
        break;
    }
  }

  onMultiCheckBoxChange(index: number, value: boolean) {
    this.selectedFilter.current.values[index] = value;

    this.setCurrentValue({ values: this.selectedFilter.current.values });
  }
}
