<div id="{{'sub-context-menu-' + subMenuName}}" class="context-menu-item-container">
  <ul>
    <div *ngFor="let item of menu; let c = index">
      <li id="{{'li-' + item.key}}" (click)="item.isSubTitle || onClick(item)"
          (mouseenter)="onMouseEnter($event, item)" (mouseleave)="onMouseLeave($event, item)"
          [ngClass]="{'rounded-b': c == menu.length - 1, 'rounded-t': (c == 0 && isSubMenu),
                      'disabled': item.disabled || item.isSubTitle,
                      'sub-title-center': item.isSubTitle}">
        <div class="label-container">
          <div *ngIf="item.image" class="icon-container">
            <svg-image [type]="item.image?.type"
                       [containerSize]="item.image?.containerSize"
                       [size]="item.image?.imageSize"
                       [fill]="item.image?.fill"
                       [ariaHidden]="item.image?.ariaHidden != null ? item.image?.ariaHidden : true"
                       [position]="item.image?.imagePosition != null ? item.image?.imagePosition : {x: 0, y: 0}"
                       [customBase64]="item.image?.base64" [customBase64Disabled]="item.image?.base64Disabled" [border]="item.image?.showBorder"
                       [fillDisabled]="disabledColor"
                       [disabled]="item.disabled" ></svg-image>
          </div>
          <span class="item" [ngClass]="{'sub-title': item.isSubTitle}"
                [ngStyle]="item.disabled && {'color': disabledColor}">{{item.label | translate}}
          </span>
        </div>
        <div class="expand-container">
          <span class="shortcut" *ngIf="item.shortCut && item.children == null">{{item.shortCut}}</span>
          <svg-image *ngIf="item.children?.length > 0" [type]="eIcon.expand" [containerSize]="{width: 20, height: 20}" [size]="{width: 24, height: 24}"></svg-image>
        </div>
      </li>

      <hr *ngIf="c < menu.length - 1 && !item.hideSeparator && !item.isSubTitle">
      <div *ngIf="item.children?.length > 0 && item.isExpanded" class="context-sub-menu-item-container"
           [ngStyle]="{'left.px': item.subMenuPosition?.x, 'top.px': item.subMenuPosition?.y}">
        <context-menu-item [menu]="item.children" [isVisible]="item.isExpanded" [subMenuName]="item.key"
                           (onItemClick)="onSubItemClicked($event)"></context-menu-item>
      </div>
    </div>
  </ul>
</div>
