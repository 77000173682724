<div *ngIf="config != null">
  <label *ngIf="showLabel" class="bold">{{label}}</label>
  <div class="relative col-span-1">
    <input [id]="formId" class="custom-input small" [locale]="locale"
          placeholder="{{'Select a month' | translate}}" [ngModel]="value"
          angular-mydatepicker [options]="options" #monthCalendar="angular-mydatepicker"
          (click)="click()"
          (calendarViewChanged)="calendarViewChanged($event)"
          (calendarToggle)="calendarToggle($event)"
          (viewActivated)="calendarViewActivated($event)">
  </div>
</div>
