import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IResponse, IUser, Login} from '../models/auth.model';
import {IRequestPasswordReset, IRequestRegister} from '../models/requests.model';
import {catchError, first, map} from 'rxjs/operators';
import {EventService} from './events.service';
import {toggleModal} from "../../custom-component-lib/classes/shared-functions";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  currentEmail: string;
  isLoggedIn = false;

  private currentUserSubject: BehaviorSubject<Login>;
  private apiError: BehaviorSubject<string>;

  constructor(private httpClient: HttpClient,
              private eventService: EventService) {
    this.apiError = new BehaviorSubject<string>('');
    this.setCurrentUser();
  }

  public get currentUserValue(): Login {
    this.setCurrentUser();

    return this.currentUserSubject.value;
  }

  get currentUser(): IUser {
    return this.currentUserValue.user;
  }

  login(email: string, password: string) {
    let body = {email: email, password: password};

    return this.httpClient.post<any>(`${environment.apiUrl}/auth/login`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        let res = new IResponse().deserialize(response);

        if (res.status) {
          let login = new Login().deserialize(response.data);
          localStorage.setItem('currentUser', JSON.stringify(login));
          this.currentUserSubject.next(login);
        }

        this.setLoginState(res.status);

        return response;
      }));
  }

  loginSetFromSocial(token: string) {
    localStorage.setItem('currentUser', token);
    this.currentUserSubject.next(<Login>JSON.parse(token));
    this.setLoginState(true);
  }

  logout(callBackend: boolean = true) {
    let body = {closeAll: 0};

    if (callBackend) {
      return this.httpClient.post<any>(`${environment.apiUrl}/auth/logout`, body, {
        headers: this.getHeaders()
      })
        .pipe(map(response => {
          this.logoutResources();

          return new IResponse().deserialize(response);
        }));
    } else {
      this.logoutResources();
      return null;
    }
  }

  register(data: IRequestRegister) {
    return this.httpClient.post<any>(`${environment.apiUrl}/auth/register`, data, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        // console.log('register response', response);
        let res = new IResponse().deserialize(response);

        if (res.status) {
          let login = new Login().deserialize(response.data);
          localStorage.setItem('currentUser', JSON.stringify(login));
          this.currentUserSubject.next(login);
          localStorage.setItem('email', login.user.email);
        }

        this.setLoginState(res.status, false);

        return response;
      }));
  }

  confirmEmail(email: string, code: string) {
    let body = {email: email, code: code};

    return this.httpClient.post<any>(`${environment.apiUrl}/auth/confirm`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        let res = new IResponse().deserialize(response);
        if (res.status) {
        }
        return res;
      }));
  }

  verificationNotification() {
    return this.httpClient.post<any>(`${environment.apiUrl}/auth/verification-notification`, {}, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        // console.log('verificationNotification response', response);
        let res = new IResponse().deserialize(response);

        return res;
      }));
  }

  forgotPassword(email: string) {
    let body = {email: email};

    return this.httpClient.post<any>(`${environment.apiUrl}/auth/forgot`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        // console.log('forgotPassword response', response);
        let res = new IResponse().deserialize(response);

        return res;
      }));
  }

  resetPassword(body: IRequestPasswordReset) {
    return this.httpClient.post<any>(`${environment.apiUrl}/auth/reset`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        return new IResponse().deserialize(response)
      }));
  }

  socialAuthentication(driver: string) {
    return environment.apiUrl + '/auth/social/redirect/?driver=' + driver;
  }

  getUserInfo() {
    return this.httpClient.get<any>(`${environment.apiUrl}/auth/me`, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        let res = new IResponse().deserialize(response);

        if (res.status) {
          this.currentUserSubject.value.user = <IUser>res.data;

          localStorage.setItem('currentUser', JSON.stringify(this.currentUserSubject.value));
          this.eventService.onUserProfileChange();
        }

        return response;
      }));
  }

  private setCurrentUser() {
    this.currentUserSubject = new BehaviorSubject<Login>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentEmail = localStorage.getItem('email');
    this.isLoggedIn = this.currentUserSubject.value != null;
  }

  private setLoginState(value: boolean, fromLogin: boolean = true) {
    this.isLoggedIn = value;

    if (fromLogin) {
      this.eventService.onLoginStateChange(this.isLoggedIn);
    }
  }

  private logoutResources() {
    this.clearDataFromLocalStorage();
    this.currentUserSubject.next(null);
    this.setLoginState(false);
  }

  private clearDataFromLocalStorage() {
    localStorage.removeItem('currentUser');
  }

  private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
  }

  //ERRORS
  showError(error: string) {
    this.apiError.next(error);
    toggleModal('api-error-popup', true);
  }

  getApiError(): Observable<string> {
    return this.apiError.asObservable();
  }
}
