import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { SpinnerType } from 'ngx-ui-loader/lib/utils/types';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, switchMap, takeUntil, tap} from 'rxjs/operators';
import { IFormConfig } from 'src/custom-component-lib/classes/interfaces';

@Component({
  selector: 'my-input',
  templateUrl: './my-input.component.html',
  styleUrls: ['./my-input.component.scss']
})
export class MyInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: IFormConfig = null;
  @Input() showLabel: boolean = false;
  @Input() formId: string = '';
  @Input() value: any;

  @Output() onChange = new EventEmitter<any>();

  value$ = new BehaviorSubject<string>('');
  results$: Observable<any>;

  isSearching: boolean = false;
  hasSearched: boolean = false;

  loaderType: SpinnerType = SPINNER.pulse; //SPINNER.foldingCube;
  loaderSize: number = 30;

  subscription: Subscription;
  data: any;

  autocomplete = (time, selector) => (source$) => source$.pipe(
    debounceTime(time),
    distinctUntilChanged(),
    tap(()=> {
      this.isSearching = true;
      this.ngxLoader.startBackgroundLoader('autocomplete-loader');
    }),
    switchMap((...args: any[]) =>
      selector(...args)
        .pipe(
          takeUntil(
            source$
              .pipe(
                skip(3)
              )
          )
        )
      ),
    )

  constructor(private ngxLoader: NgxUiLoaderService) {
  }

  ngOnInit() {
    this.subscription = this.results$.subscribe(
      (response) => this.data = response
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] &&  this.config) {
      if (this.config?.input?.autoCompleteFunctionEnabled) {
        this.results$ = this.value$.pipe(
          this.autocomplete(500, (value => this.search(value)))
        )
      }
    }
  }

  changed(v: any) {
    if (!this.config?.input?.autoCompleteFunctionEnabled) {
      this.onChange.emit(v);
    }
  }

  onItemClick(value: string) {
    this.hasSearched = false;
    this.onChange.emit(value);
  }

  search(value: string): Observable<any> {
    if (value != '') {
      this.hasSearched = true;
      return this.config.input?.autoCompleteFunction(value).pipe(
        tap(()=> {
          this.setSearchOff();
        }),
      );
    }
    else {
      this.hasSearched = false;
      this.setSearchOff();

      return of([]);
    }
  }

  private setSearchOff() {
    this.isSearching = false;
    this.ngxLoader.stopBackgroundLoader('autocomplete-loader');
  }
}

