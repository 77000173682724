import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      return true;
    }

    if (this.router.url != '/home') {
      this.router.navigate(['/home'], {queryParams: {returnUrl: state.url}});
    }

    return false;
  }
}
