<nav class="nav-container">
  <div class="container mx-auto">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center md:hidden">
        <!-- Mobile menu button-->
        <button type="button" class="hamburger" aria-controls="mobile-menu" aria-expanded="false" (click)="mobileMenuIsOpen = !mobileMenuIsOpen">
          <span class="sr-only">Open main menu</span>
          <svg class="h-6 w-6" [ngClass]="{'hidden': mobileMenuIsOpen, 'block': !mobileMenuIsOpen}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg class="h-6 w-6" [ngClass]="{'hidden': !mobileMenuIsOpen, 'block': mobileMenuIsOpen}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <img class="h-8 w-auto" src="/assets/images/logo.jpeg" alt="Workflow">
          <span class="title-logo">{{title}}</span>
        </div>
        <div class="hidden md:block md:ml-6">
          <div class="flex space-x-2">
            <a class="desktop" [routerLink]="['/home']" [routerLinkActive]="'active'" aria-current="page">Home</a>
<!--            <a class="desktop" [routerLink]="['/public/list']" [routerLinkActive]="'active'" aria-current="page">{{'Our ferris wheels' | translate}}</a>-->
            <a *ngIf="isLoggedIn && (userPermissions['USERS'] > 0 || userPermissions['CONFIG_SYSTEM'] > 0 || userPermissions['MAINTENANCE_SYSTEMS'] > 0)"
               class="desktop" [routerLink]="['/system-management']" [routerLinkActive]="'active'">{{'System Management' | translate}}</a>
            <a *ngIf="isLoggedIn && userPermissions['REPORTS'] > 0" class="desktop" [routerLink]="['/reports']" [routerLinkActive]="'active'">{{'Reports' | translate}}</a>
            <a *ngIf="isLoggedIn && user?.role == 'User'" class="desktop" [routerLink]="['/user-profile']" [routerLinkActive]="'active'" aria-current="page">{{'Your Profile' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">

        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div>
            <button *ngIf="!isLoggedIn" type="button" class="btn-menu-profile" (click)="showHideLogin(true)" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Login</span>
              <img class="h-10 w-10 p-1 rounded-full" alt="" src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBoZWlnaHQ9IjQ4cHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgd2lkdGg9IjQ4cHgiIGZpbGw9IndoaXRlIj48Zz48cmVjdCBmaWxsPSJub25lIiBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiIHg9IjAiLz48L2c+PGc+PHBhdGggZD0iTTEwLDR2MWg2djEwaC02djFoNmMwLjU1LDAsMS0wLjQ1LDEtMVY1YzAtMC41NS0wLjQ1LTEtMS0xSDEweiIvPjxwb2x5Z29uIHBvaW50cz0iOS41LDYuNSA4Ljc5LDcuMjEgMTEuMDksOS41IDMsOS41IDMsMTAuNSAxMS4wOSwxMC41IDguNzksMTIuNzkgOS41LDEzLjUgMTMsMTAiLz48L2c+PC9zdmc+'/>
            </button>
            <button *ngIf="isLoggedIn" type="button" class="btn-menu-profile" (click)="profileMenuIsOpen = !profileMenuIsOpen" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Open user menu</span>
              <img class="h-10 w-10 p-1 rounded-full" alt="" src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDhweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iNDhweCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyIDUuOWMxLjE2IDAgMi4xLjk0IDIuMSAyLjFzLS45NCAyLjEtMi4xIDIuMVM5LjkgOS4xNiA5LjkgOHMuOTQtMi4xIDIuMS0yLjFtMCA5YzIuOTcgMCA2LjEgMS40NiA2LjEgMi4xdjEuMUg1LjlWMTdjMC0uNjQgMy4xMy0yLjEgNi4xLTIuMU0xMiA0QzkuNzkgNCA4IDUuNzkgOCA4czEuNzkgNCA0IDQgNC0xLjc5IDQtNC0xLjc5LTQtNC00em0wIDljLTIuNjcgMC04IDEuMzQtOCA0djNoMTZ2LTNjMC0yLjY2LTUuMzMtNC04LTR6Ii8+PC9zdmc+'/>
            </button>
          </div>

          <!-- Dropdown menu -->
          <div class="div-menu-profile" [ngClass]="{'hidden': !profileMenuIsOpen, 'block': profileMenuIsOpen}"
               role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1"
               (mouseleave)="profileMenuIsOpen = false">
            <!-- Active: "bg-gray-100", Not Active: "" -->
            <a *ngIf="user?.role == 'User'" role="button" class="menu-item" tabindex="-1" id="user-menu-item-0"
               [routerLink]="['/user-profile']" [routerLinkActive]="'active'">{{'Your Profile' | translate}}</a>
            <a role="button" class="menu-item" tabindex="-1" id="user-menu-item-2" (click)="showLogout(true)">{{'Sign out' | translate}}</a>
            <hr>
            <span class="menu-item-user">{{userInfo}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu -->
  <div class="md:hidden" [ngClass]="{'hidden': !mobileMenuIsOpen, 'block': mobileMenuIsOpen}" id="mobile-menu">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <a class="mobile" [routerLink]="['/home']" [routerLinkActive]="'active'" aria-current="page"
         (click)="mobileMenuIsOpen = false">Home</a>
<!--      <a class="mobile" [routerLink]="['/public/list']" [routerLinkActive]="'active'" aria-current="page">{{'Our ferris wheels' | translate}}</a>-->
      <div *ngIf="isLoggedIn">
        <a *ngIf="userPermissions['USERS'] > 0 || userPermissions['CONFIG_SYSTEM'] > 0 || userPermissions['MAINTENANCE_SYSTEMS'] > 0"
           class="mobile" [routerLink]="['/system-management']" [routerLinkActive]="'active'">{{'System Management' | translate}}</a>
        <a *ngIf="userPermissions['REPORTS'] > 0" class="mobile" [routerLink]="['/reports']" [routerLinkActive]="'active'">{{'Reports' | translate}}</a>
        <a *ngIf="isLoggedIn && user.role == 'User'" class="mobile" [routerLink]="['/user-profile']" [routerLinkActive]="'active'" aria-current="page">{{'Your Profile' | translate}}</a>
      </div>
    </div>
  </div>
</nav>

<div class="background-container" [ngClass]="{'bg-image': isHome}">
  <div *ngIf="isHome">
    <div class="home-container">
      <div class="router-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div *ngIf="!isHome">
    <div class="dashboard-container">
      <div class="mobile-page-header-container">
        <mobile-page-header [title]="currentMobileHeaderTitle"></mobile-page-header>
      </div>
      <div class="router-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<modal-login [modalId]="'login-modal'" [title]="'System Login' | translate" [showLostPassword]="true"
             [configurations]="loginConfiguration"
             [lostPasswordLabel]="'Lost Password?' | translate" [showRegisterUser]="true"
             [registerUserLabel]="'Not yet registered?' | translate" [registerUser2Label]="'Create your account' | translate"
             (onRegisterUser)="onRegisterUser()" (onLostPassword)="onLostPassword()"
             (dataUpdate)="login($event)"></modal-login>

<modal-register [modalId]="'register-modal'" [title]="'User Registration' | translate"
             (dataUpdate)="register($event)" [configurations]="registerConfigurations"></modal-register>

<modal [modalId]="'registration-ok-modal'" [showSeparators]="false" [title]="'Registration Complete!' | translate"
       [normalSize]="false" [widthFull]="false" [titleCss]="'modal-title'" (onClose)="onRegistrationOKClose()">
  <div body class="modal-body">
    <span class="block">{{'We have sent you a link to verify your email address and continue with the registration.' | translate}}</span>
    <br>
    <span>{{'Didn\'t receive emails? If you can\'t find the email, check the Spam folder.' | translate}}</span>
  </div>
  <div footer class="modal-footer">
    <custom-button class="ml-3 button-size" cssType="btn-outline-primary" [width]="eCustomButtonWidth.full"
                   [text]="'Send Again' | translate" (click)="resendRegisterEmail()"></custom-button>
  </div>
</modal>

<modal-forgotten-password [modalId]="'forgotten-password-modal'" [title]="'Reset password' | translate"
                          [message]="'Enter the email with which you registered. A message will be sent to you containing the link to update your password!' | translate"
                          [btnText]="'Send Request' | translate"
                          (dataUpdate)="onLostPasswordClick($event)" [configurations]="forgottenPasswordConfigurations">
</modal-forgotten-password>

<modal [modalId]="'forgotten-password-ok-modal'" [showSeparators]="false" [title]="'Reset Password received!' | translate"
       [normalSize]="false" [widthFull]="false" [titleCss]="'modal-title'" (onClose)="onLostPasswordOKClose()">
  <div body class="modal-body">
    <span class="block">{{'Your request has been successfully sent to the address' | translate}} </span>
    <span>{{forgottenPwdEmail}}. </span>
    <span>{{'Open the link we sent you and update your password!' | translate}}</span>
    <br>
    <span>{{'Didn\'t receive emails? If you can\'t find the email, check the Spam folder.' | translate}}</span>
  </div>
  <div footer class="modal-footer">
    <custom-button class="ml-3 button-size" cssType="btn-outline-primary" [width]="eCustomButtonWidth.full"
                   [text]="'Send Again' | translate" (click)="resendForgottenPassword()"></custom-button>
  </div>
</modal>

<modal [modalId]="'logout-popup'" [isPopup]="true" [popupIcon]="3" [popupMessage]="'Disconnettere l\'utente corrente?'">
  <div popup-footer class="modal-footer">
    <custom-button class="button-size" cssType="btn-primary" [width]="eCustomButtonWidth.full" text="OK" (onClick)="logout()"></custom-button>
    <custom-button class="button-size" cssType="btn-outline-black" css="ml-3" [width]="eCustomButtonWidth.full" text="Annulla" (onClick)="showLogout(false)"></custom-button>
  </div>
</modal>
