import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActiveView, AngularMyDatePickerDirective, CalAnimation, DefaultView, IAngularMyDpOptions, IMyCalendarViewChanged, IMyDateModel } from '@nodro7/angular-mydatepicker';
import { IFormConfig } from 'src/custom-component-lib/classes/interfaces';
import { dateUtils } from 'src/custom-component-lib/classes/utility';

@Component({
  selector: 'my-month-selector',
  templateUrl: './my-month-selector.component.html',
  styleUrls: ['./my-month-selector.component.scss']
})
export class MyMonthSelectorComponent  implements OnInit {
  @Input() config: IFormConfig = null;
  @Input() showLabel: boolean = false;
  @Input() formId: string = '';
  @Input() value: any;
  @Input() openOnClick: boolean = false;
  @Input() options: IAngularMyDpOptions = {
    calendarAnimation: { in: CalAnimation.Fade, out: CalAnimation.None },
    markCurrentDay: false,
    dateRange: false,
    defaultView: DefaultView.Month,
    dateFormat: 'mm/yyyy',
  }
  @Input() locale: string;
  @Input() label: string;

  @Output() onChange = new EventEmitter<IMyDateModel>();
  @Output() onClick = new EventEmitter<AngularMyDatePickerDirective>();

  @ViewChild('monthCalendar') monthCalendarComponent: AngularMyDatePickerDirective;

  isOpen: boolean = false;


  constructor() {

  }

  ngOnInit() {

  }

  click() {
    if (this.openOnClick) this.open();

    this.onClick.emit(this.monthCalendarComponent);
  }

  calendarViewChanged(e: IMyCalendarViewChanged) {
    if (this.isOpen) {
      let date = dateUtils.ngbGetDefaultDateModel({ year: e.year, month: e.month, day: 1 });
      this.onChange.emit(date);
    }
  }

  calendarToggle(e: number, from: boolean = false) {
    switch (e) {
      case 1:
        //open
        break;
      case 3:
      case 4:
        //close
        if (e == 3 && this.value == null) {
          let date = dateUtils.ngbGetCurrentMonth();
          this.onChange.emit(date);
        }

        this.isOpen = false;
        break;
      default:
        break;
    }
  }

 calendarViewActivated(e: ActiveView, from: boolean = false) {
    if (e == ActiveView.Date) {
      this.close();
    }
  }

  open() {
    this.monthCalendarComponent.openCalendar();
    this.isOpen = true;
  }

  close() {
    this.monthCalendarComponent.closeCalendar();
    this.isOpen = false;
  }

  isDateValid() {
    return this.monthCalendarComponent.isDateValid();
  }
}


