export function toggleModal(modalId: string, show: boolean, focusElementId: string = '', lowOpacity: boolean = false) {
  let modalEl = document.getElementById(modalId);

  if (modalEl) {
    if (show) {
      modalEl.classList.add('flex');
      modalEl.classList.remove('hidden');
      modalEl.setAttribute('aria-modal', 'true');
      modalEl.setAttribute('role', 'dialog');
      modalEl.removeAttribute('aria-hidden');

      // create backdrop element
      let backdropEl = document.createElement('div');
      backdropEl.setAttribute('modal-backdrop', '');
      backdropEl.classList.add('bg-black', lowOpacity ? 'bg-opacity-5' : 'bg-opacity-60', 'fixed', 'inset-0', 'z-40');
      document.querySelector('body').append(backdropEl);

      if (focusElementId != '') {
        let el = <HTMLElement>document.getElementById(focusElementId);
        if (!el) {
          el = <HTMLElement>document.querySelector('[ng-reflect-name="' + focusElementId + '"]');
        }
        if (el) {
          el.focus();
        }
      }
      else {
        let m = modalEl.getElementsByTagName('button');
        if (m.length > 0) {
          let el = <HTMLElement>m[0];
          el.focus();
        }
      }
    }
    else {
      modalEl.classList.add('hidden');
      modalEl.classList.remove('flex');
      modalEl.setAttribute('aria-hidden', 'true');
      modalEl.removeAttribute('aria-modal');
      modalEl.removeAttribute('role');
      let modalBackdrop = document.querySelector('[modal-backdrop]');
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    }
  }
}

export function hasPermission(permission: number, grant: number) {
  return (permission & grant) > 0;
}

export function getScrollbarWidth() {
  var inner = document.createElement('p');
  inner.style.width = "100%";
  inner.style.height = "200px";

  var outer = document.createElement('div');
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild (inner);

  document.body.appendChild (outer);
  var w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  var w2 = inner.offsetWidth;
  if (w1 == w2) w2 = outer.clientWidth;

  document.body.removeChild (outer);

  return (w1 - w2);
}
