<div class="w-full">
  <label [ngClass]="{'small': labelSmall}">{{label | translate}}</label>
  <input #fileSelect type='file' (change)="onFileChange($event)" [multiple]="multiple" hidden/>
  <div class="main-container">
    <div *ngFor="let file of files" class="row-main-container">
      <div class="row-container">
        <div class="text-sm">
          {{file.name}}
        </div>
        <div class="trash-container" (click)="deleteFile(file)">
          <svg enable-background="new 0 0 32 32" id="Glyph" viewBox="0 0 32 32">
            <path d="M6,12v15c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V12H6z M12,25c0,0.552-0.448,1-1,1s-1-0.448-1-1v-9  c0-0.552,0.448-1,1-1s1,0.448,1,1V25z M17,25c0,0.552-0.448,1-1,1s-1-0.448-1-1v-9c0-0.552,0.448-1,1-1s1,0.448,1,1V25z M22,25  c0,0.552-0.448,1-1,1s-1-0.448-1-1v-9c0-0.552,0.448-1,1-1s1,0.448,1,1V25z" id="XMLID_237_"/><path d="M27,6h-6V5c0-1.654-1.346-3-3-3h-4c-1.654,0-3,1.346-3,3v1H5C3.897,6,3,6.897,3,8v1c0,0.552,0.448,1,1,1h24  c0.552,0,1-0.448,1-1V8C29,6.897,28.103,6,27,6z M13,5c0-0.551,0.449-1,1-1h4c0.551,0,1,0.449,1,1v1h-6V5z" id="XMLID_243_"/>
          </svg>
        </div>
      </div>
    </div>
    <div [ngClass]="{'border-t mt-2': files.length > 0}">
      <div class="text-center" [ngClass]="{'mt-2': files.length > 0}">
        <span class="text-link" (click)="fileSelect.click()">Clicca qui per selezionare i file</span>
      </div>
    </div>
  </div>
</div>
<p *ngIf="submitted && files.length == 0" class="error-text mt-1">{{'The field' | translate}} 'File' {{'is mandatory' | translate}}</p>
