import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IDetailFormConfiguration, IDetailsFormDataUpdate} from "../classes/interfaces";
import {eCustomButtonWidth, eDetailFormInputType, eDetailFormType} from "../classes/enums";
import {DetailFormComponent} from "../detail-form/detail-form.component";

@Component({
  selector: 'modal-reset-password',
  templateUrl: './modal-reset-password.component.html',
  styleUrls: ['./modal-reset-password.component.scss']
})
export class ModalResetPasswordComponent implements OnInit {
  @Input() modalId: string = '';
  @Input() title: string = 'Reset Password';
  @Input() titleCss: string = '';
  @Input() message: string = '';
  @Input() btnText: string = 'Set new password';
  @Input() configurations: IDetailFormConfiguration[] = [
    {key: 'email', label: 'Email', type: eDetailFormType.input, inputType: eDetailFormInputType.email, validationRequired: true, fullRow: true},
    {key: 'password', label: 'Password', type: eDetailFormType.input, inputType: eDetailFormInputType.password, validationRequired: true, fullRow: true},
    {key: 'password_confirmation', label: 'Confirm Password', type: eDetailFormType.input, inputType: eDetailFormInputType.password, validationRequired: true, fullRow: true}];

  @Output() dataUpdate = new EventEmitter<IDetailsFormDataUpdate>();

  eCustomButtonWidth = eCustomButtonWidth;
  passwordNotEqual: boolean = false;

  @ViewChild(DetailFormComponent) detailFormComponent: DetailFormComponent;

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(data: IDetailsFormDataUpdate) {
    this.dataUpdate.emit(data);
  }

  resetData() {
    this.detailFormComponent.reset();
  }

  onClose() {
    this.resetData();
  }
}
