import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mobile-page-header',
  templateUrl: './mobile-page-header.component.html',
  styleUrls: ['./mobile-page-header.component.scss']
})
export class MobilePageHeaderComponent implements OnInit {
  @Input() title: string = 'Title';
  @Input() css: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
