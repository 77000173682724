import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IDetailFormConfiguration, IDetailsFormDataUpdate} from "../../../custom-component-lib/classes/interfaces";
import {Events, EventService} from "../../services/events.service";
import {eCustomButtonWidth, eDetailFormInputType, eDetailFormType} from "../../../custom-component-lib/classes/enums";
import {toggleModal} from "../../../custom-component-lib/classes/shared-functions";
import {ActivatedRoute, NavigationEnd, Router, Event} from "@angular/router";
import {Subscription} from "rxjs";
import {AuthenticationService} from "../../services/authentication.service";
import {first} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ModalLoginComponent} from "../../../custom-component-lib/modal-login/modal-login.component";
import {ModalRegisterComponent} from "../../../custom-component-lib/modal-register/modal-register.component";
import {IRequestRegister} from "../../models/requests.model";
import {IUser} from "../../models/auth.model";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  title: string = "Le Cupole";
  currentMobileHeaderTitle: string = '';
  isLoggedIn: boolean = false;
  loginConfiguration: IDetailFormConfiguration[] = [];
  registerConfigurations: IDetailFormConfiguration[] = [];
  forgottenPasswordConfigurations: IDetailFormConfiguration[] = [];

  subscriptions: Subscription[] = [];
  mobileMenuIsOpen: boolean = false;
  profileMenuIsOpen: boolean = false;
  userInfo: string = '';
  userPermissions: any;
  forgottenPwdEmail: string = '';
  user: IUser;

  eCustomButtonWidth = eCustomButtonWidth;

  @ViewChild(ModalLoginComponent) modalLoginComponent: ModalLoginComponent;
  @ViewChild(ModalRegisterComponent) modalRegisterComponent: ModalRegisterComponent;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthenticationService,
              private eventService: EventService,
              private trans: TranslateService) { }

  ngOnInit(): void {
    this.attachEvent();
  }

  ngAfterViewInit() {
    this.setUserInfo();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get isHome() {
    return this.router.url.includes('home');
  }

  private attachEvent() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.mobileMenuIsOpen = false;
        this.setMobileHeaderTitle();
      }
    });

    this.subscriptions.push(this.eventService.on(Events.translationsLoaded, (value) => {
      this.initConfigurations();
    }));

    this.subscriptions.push(this.eventService.on(Events.loginStateChanged, (value) => {
      this.isLoggedIn = value;
      this.setUserInfo();
    }));

    this.subscriptions.push(this.eventService.on(Events.userProfileChanged, (value) => {
      this.setUserInfo();
    }));

    this.isLoggedIn = this.authService.isLoggedIn;
    this.setUserInfo();
    this.setMobileHeaderTitle();
    this.initConfigurations();
  }

  private setMobileHeaderTitle() {
    if (this.route.snapshot.firstChild.data['title']) {
      this.currentMobileHeaderTitle = this.trans.instant(this.route.snapshot.firstChild.data['title']);
    }
    else {
      this.currentMobileHeaderTitle = '';
    }
  }

  private setUserInfo() {
    if (this.isLoggedIn) {
      this.user = this.authService.currentUserValue.user;
      this.userInfo = this.user.name;
      this.userPermissions = this.user.permissions;
    }
    else {
      this.userInfo = '';
    }
  }

  private initConfigurations() {
    this.loginConfiguration = [{key: 'email', label: 'Email', type: eDetailFormType.input, inputType: eDetailFormInputType.email, validationRequired: true, fullRow: true},
      {key: 'password', label: 'Password', type: eDetailFormType.input, inputType: eDetailFormInputType.password, validationRequired: true, fullRow: true}];

    this.registerConfigurations = [
      {key: 'first_name', label: this.trans.instant('Firstname'), type: eDetailFormType.input, inputType: eDetailFormInputType.text, validationRequired: true, stringLength: {min: 3}, fullRow: true},
      {key: 'last_name', label: this.trans.instant('Lastname'), type: eDetailFormType.input, inputType: eDetailFormInputType.text, validationRequired: true, fullRow: true},
      {key: 'email', label: 'Email', type: eDetailFormType.input, inputType: eDetailFormInputType.email, validationRequired: true, fullRow: true},
      {key: 'password', label: 'Password', type: eDetailFormType.input, inputType: eDetailFormInputType.password, validationRequired: true, fullRow: true},
      {key: 'password_confirmation', label: this.trans.instant('Confirm Password'), type: eDetailFormType.input, inputType: eDetailFormInputType.password,
        validationRequired: true, matchPasswordValidator: true, fullRow: true}];

    this.forgottenPasswordConfigurations = [{key: 'email', label: 'Email', type: eDetailFormType.input,
      inputType: eDetailFormInputType.email, validationRequired: true, fullRow: true}];
  }

  login(data: IDetailsFormDataUpdate) {
    this.showHideLogin(false);

    this.authService.login(data.items.email, data.items.password)
      .pipe(first())
      .subscribe(
        data => {
        },
        error => {
          // this.alertService.error(this.translateService.instant('Error'), this.translateService.instant('Wrong User or Password!'));
          console.log('Error', error.error);
        });
  }

  logout() {
    this.showLogout(false);

    this.authService.logout().pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/home']);
        },
        error => {
          // this.alertService.error(this.translateService.instant('Error'), this.translateService.instant('Wrong User or Password!'));
          console.log('Error', error.error);
        });
  }

  register(data: IDetailsFormDataUpdate) {
    let body = <IRequestRegister>data.items;
    body.newsletter = false;
    this.authService.register(body)
      .pipe(first())
      .subscribe(
        data => {
          this.showHideRegistration(false);
          this.showHideRegistrationOK(true);
         },
        err => {
          console.log('register Error', err.error);

          // if (err.error.error.errors.email) {
          //   this.registrationEmailAlreadyUsed = true;
          // } else {
          //   this.registrationGenericError = true;
          // }
        });
  }

  resendRegisterEmail() {
    this.showHideRegistrationOK(false);
    this.authService.verificationNotification()
      .pipe(first())
      .subscribe(
        data => {
          localStorage.setItem('email', this.authService.currentUserValue.user.email);
          this.showHideRegistrationOK(true);
        },
        err => {
          console.log('forgotten password Error', err.error);
        });
  }

  onLostPasswordClick(data: IDetailsFormDataUpdate) {
    this.forgottenPwdEmail = data.items.email;

    this.showHideForgottenPassword(false);
    this.resendForgottenPassword();
  }

  resendForgottenPassword() {
    this.showHideForgottenPasswordOK(false);
    this.authService.forgotPassword(this.forgottenPwdEmail)
      .pipe(first())
      .subscribe(
        data => {
          this.showHideForgottenPasswordOK(true);
        },
        err => {
          console.log('register Error', err.error);

          // if (err.error.error.errors.email) {
          //   this.forgottenEmailUnknow = true;
          // } else {
          //   this.forgottenEmailGenericError = true;
          // }
        });
  }

  //Modals
  showHideLogin(show: boolean) {
    if (show) this.modalLoginComponent.resetData();
    toggleModal('login-modal', show, 'email');
  }

  showHideRegistration(show: boolean) {
    if (show) this.modalRegisterComponent.resetData();
    toggleModal('register-modal', show, 'first_name');
  }

  showHideRegistrationOK(show: boolean) {
    toggleModal('registration-ok-modal', show);
  }

  showHideForgottenPassword(show: boolean) {
    toggleModal('forgotten-password-modal', show, 'email');
  }

  showHideForgottenPasswordOK(show: boolean) {
    toggleModal('forgotten-password-ok-modal', show);
  }

  showLogout(show: boolean) {
    if (show) {
      this.profileMenuIsOpen = false;
    }
    toggleModal('logout-popup', show);
  }

  onLostPassword() {
    this.showHideLogin(false);
    this.showHideForgottenPassword(true);
  }

  onRegisterUser() {
    this.showHideLogin(false);
    this.showHideRegistration(true);
  }

  onRegistrationOKClose() {
    this.logout();
  }

  onLostPasswordOKClose() {
    //
  }
}
