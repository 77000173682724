import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'back-header',
  templateUrl: './back-header.component.html',
  styleUrls: ['./back-header.component.scss']
})
export class BackHeaderComponent implements OnInit {
  @Input() backText: string = 'Back';
  @Input() cssText: string = '';
  @Input() autoGoBack: boolean = false;

  @Output() onBackClick = new EventEmitter();

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  onClick(ev: any) {
    if (this.autoGoBack) {
      let currentUrl = this.router.url;
      let url = currentUrl.slice(0, currentUrl.lastIndexOf('/'));
      this.router.navigate([url]);
    }
    else {
      this.onBackClick.emit();
    }
  }
}
