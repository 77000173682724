<modal [modalId]="modalId" [showSeparators]="false" [showFooterButtons]="false" [normalSize]="false"
       [widthFull]="false" [titleCss]="titleCss" [alignCenter]="alignCenter" (onClose)="onClose()">
  <div body class="modal-body-details">
    <detail-form [configurations]="configurations" [title]="title" (dataUpdate)="onSubmit($event)" [autoCompletePassword]="false" [selectsLists]="selectsLists">
      <div footer>
        <div class="mt-4 sm:mt-5">
          <custom-button cssType="btn-primary" [width]="eCustomButtonWidth.full" [isSubmit]="true" [text]="btnText | translate"></custom-button>
        </div>
      </div>
    </detail-form>
  </div>
</modal>
