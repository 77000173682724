import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IDetailFormConfiguration, IDetailsFormDataUpdate} from "../classes/interfaces";
import {eCustomButtonWidth, eDetailFormInputType, eDetailFormType} from "../classes/enums";
import {DetailFormComponent} from "../detail-form/detail-form.component";

@Component({
  selector: 'modal-forgotten-password',
  templateUrl: './modal-forgotten-password.component.html',
  styleUrls: ['./modal-forgotten-password.component.scss']
})
export class ModalForgottenPasswordComponent implements OnInit {
  @Input() modalId: string = '';
  @Input() title: string = 'Reset password';
  @Input() titleCss: string = '';
  @Input() message: string = '';
  @Input() btnText: string = 'Send Request';
  @Input() configurations: IDetailFormConfiguration[] = [{key: 'email', label: 'Email', type: eDetailFormType.input,
    inputType: eDetailFormInputType.email, validationRequired: true, fullRow: true}];

  @Output() dataUpdate = new EventEmitter<IDetailsFormDataUpdate>();

  eCustomButtonWidth = eCustomButtonWidth;

  @ViewChild(DetailFormComponent) detailFormComponent: DetailFormComponent;

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(data: IDetailsFormDataUpdate) {
    this.dataUpdate.emit(data);
  }

  resetData() {
    this.detailFormComponent.reset();
  }

  onClose() {
    this.resetData();
  }
}
