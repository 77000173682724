import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IDetailConfiguration} from "../classes/interfaces";
import {eDetailType} from "../classes/enums";
import {DecimalPipe} from "@angular/common";

@Component({
  selector: 'detail-controls',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnChanges {
  @Input() title: string = '';
  @Input() configurations: IDetailConfiguration[] = [];
  @Input() item: any = null;
  @Input() small: boolean = false;

  @Output() onButtonLinkClick = new EventEmitter<any>();

  eDetailType = eDetailType;

  constructor(private _decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.item && this.item) {
    //   this.setItemValues();
    // }
  }

  setItemValues() {
    for (let conf of this.configurations) {
      conf.value = this.setData(conf);
    }
  }

  setData(conf: IDetailConfiguration) {
    let key = conf.key.replace(/->/g, '.');
    let value = null;
    if (this.item) {
      try {
        value = key.split('.').reduce(function(p,prop) { return p[prop] }, this.item);
      } catch (error) {}
    }

    let v = value; //this.item[conf.key];
    if (conf.type == eDetailType.number || conf.type == eDetailType.currency ) {
      if (conf.numberNotFormatted) {
        v = this._decimalPipe.transform(v);
      }
      else {
        v = this._decimalPipe.transform(v, '1.2-2');

        if (conf.type == eDetailType.currency) {
          v = '€ ' + v;
        }
      }
    }

    if (v) {
      if (conf.type == eDetailType.text && v.trim() == '') {
        v = '-';
      }
    }
    else {
      if (conf.type == eDetailType.number || conf.type == eDetailType.currency) {
        v = 0;
      }
      else if (conf.type == eDetailType.boolean) {
        v = false;
      }
      else {
        v = '-';
      }
    }

    return v;
  }

  buttonLinkClick(v: IDetailConfiguration) {
    this.onButtonLinkClick.emit(v);
  }
}
