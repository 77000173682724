import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { eIcon } from 'src/custom-component-lib/classes/enums';
import {IContextMenu, IPos} from "../../classes/interfaces";

@Component({
  selector: 'context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss']
})
export class ContextMenuItemComponent implements OnInit, AfterViewInit {
  @Input() menu: IContextMenu[];
  @Input() isSubMenu: boolean = true;
  @Input() subMenuName: string = '';
  @Input() isVisible: boolean = false;
  @Input() offset: IPos = {x: -14, y: 16};
  @Input() objectOffsetX = 0;
  @Input() objectOffsetY = 0;
  @Input() disabledColor: string = 'lightgray';

  @Output() onItemClick = new EventEmitter<IContextMenu>();

  eIcon = eIcon;
  x = 0;
  y = 0;

  private subMenuVisible: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

  }

  onClick(item: IContextMenu) {
    if (item.children == null || item.children?.length == 0) {
      this.onItemClick.emit(item);
    }
  }

  onMouseEnter(e: MouseEvent, item: IContextMenu) {
    if (this.subMenuVisible) {
      let itemToCollapse = this.menu.filter(i => i.isExpanded);
      if (itemToCollapse.length > 0) {
        for (let i of itemToCollapse ) {
          i.isExpanded = false;
          if (i.children?.length > 0) {
            this.removeCollapse(i.children);
          }
        }
      }
    }

    if (item.children?.length > 0) {
      let parent = document.getElementById('li-' + item.key);
      let x = parent.offsetWidth + this.offset.x;
      let y = parent.offsetTop + this.offset.y;

      // console.log('parent', parent)

      if (!this.isSubMenu) {
        let mainMenu = document.getElementById('main-context-menu');
        if (mainMenu) {
          let mainContainer = mainMenu.parentElement.parentElement;

          let subMenu = document.getElementById('sub-context-menu-' + this.subMenuName).parentElement.parentElement;

          // console.log('parent, subMenu', parent, subMenu)

          if (subMenu) {
            let m = mainContainer.getBoundingClientRect();
            let s = subMenu.getBoundingClientRect();
            if (s.left + s.width - m.left > m.left + m.width - parent.offsetWidth) {
              x = -x + this.objectOffsetX;
            }
            // console.log('s.top + s.height - m.top > m.top + m.height - parent.offsetHeight', s.top, s.height, m.top, m.height, parent.offsetHeight)
            // if ((s.top + s.height + m.top) - (m.top + m.height) > parent.offsetHeight) {
            //   y -= s.height;
            // }
          }
        }
      }

      item.subMenuPosition = {x: x, y: y};
      item.isExpanded = item.children?.length > 0;
      this.subMenuVisible = item.isExpanded;
    }
  }

  onMouseLeave(e: MouseEvent, item: IContextMenu) {
    //
  }

  onSubItemClicked(item: IContextMenu) {
    this.onItemClick.emit(item);
  }

  private removeCollapse(item: IContextMenu[]) {
    let itemToCollapse = item.filter(i => i.isExpanded);
    if (itemToCollapse.length > 0) {
      for (let i of itemToCollapse ) {
        i.isExpanded = false;
        if (i.children?.length > 0) {
          this.removeCollapse(i.children);
        }
      }
    }
  }
}
