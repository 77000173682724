import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {eCustomButtonWidth, eDetailFormInputType, eDetailFormType} from "../classes/enums";
import {IDetailsFormDataUpdate, IDetailFormConfiguration} from "../classes/interfaces";
import {DetailFormComponent} from "../detail-form/detail-form.component";

@Component({
  selector: 'modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {
  @Input() modalId: string = '';
  @Input() title: string = 'Sign in to platform';
  @Input() titleCss: string = '';
  @Input() configurations: IDetailFormConfiguration[] = [
    {key: 'email', label: 'Email', type: eDetailFormType.input, inputType: eDetailFormInputType.email, validationRequired: true, fullRow: true},
    {key: 'password', label: 'Password', type: eDetailFormType.input, inputType: eDetailFormInputType.password, validationRequired: true, fullRow: true}];
  @Input() btnText: string = 'Login';
  @Input() showRememberUser: boolean = false;
  @Input() rememberUserLabel = 'Remember User';
  @Input() showLostPassword: boolean = false;
  @Input() lostPasswordLabel: string = 'Lost Password?';
  @Input() showRegisterUser: boolean = false;
  @Input() registerUserLabel: string = 'Not yet registered?';
  @Input() registerUser2Label: string = 'Create your account';

  @Output() dataUpdate = new EventEmitter<IDetailsFormDataUpdate>();
  @Output() onLostPassword = new EventEmitter();
  @Output() onRegisterUser = new EventEmitter();

  eCustomButtonWidth = eCustomButtonWidth;

  @ViewChild(DetailFormComponent) detailFormComponent: DetailFormComponent;

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(data: IDetailsFormDataUpdate) {
    this.dataUpdate.emit(data);
  }

  resetData() {
    this.detailFormComponent.reset();
  }

  onClose() {
    this.resetData();
  }
}
