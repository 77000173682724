import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {EventService} from "./events.service";
import {toggleModal} from "../../custom-component-lib/classes/shared-functions";
import {BehaviorSubject, Observable} from "rxjs";
import {ePopupIcon} from "../../custom-component-lib/classes/enums";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private generalPopup: BehaviorSubject<IGeneralPopup>;

  constructor(private translate: TranslateService,
              private eventService: EventService) {
    this.generalPopup = new BehaviorSubject<IGeneralPopup>({title: '', description: '', type: ePopupIcon.error});
  }

  showGeneralPopup(value: IGeneralPopup) {
    setTimeout(()=> {
      this.generalPopup.next(value);
      toggleModal('general-error-popup', true);
    }, 50);
  }

  showQuestionPopup(value: IGeneralPopup) {
    setTimeout(()=> {
      this.generalPopup.next(value);
      toggleModal('general-question-popup', true);
    }, 50);
  }

  getGeneralPopup(): Observable<IGeneralPopup> {
    return this.generalPopup.asObservable();
  }
}

export interface IGeneralPopup {
  title: string;
  description: string;
  type: ePopupIcon;
}
