<div class="vertical-container bg-white {{cssContainer}}"
     [ngClass]="moreButtonLabel == '' ? 'is-button ' + cssContainerHover : ''" (click)="goToMain()">
  <div>
    <img src="{{image}}" alt="" (click)="goToTitle()"/>
  </div>
  <div class="p-5">
    <h5 class="title cursor-pointer {{cssTitle}}" (click)="goToTitle()">{{title}}</h5>
    <p class="description {{cssDescription}}">{{description}}</p>
    <div *ngIf="moreButtonLabel != ''" class="mt-3">
      <custom-button cssType="btn-primary" [text]="moreButtonLabel" (onClick)="goToMore()"></custom-button>
    </div>
  </div>
</div>
